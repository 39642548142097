import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexMarkers,
  ApexYAxis,
  ApexLegend,
  ApexFill,
  ApexTooltip,
  ApexNoData,
  ApexAnnotations,
} from 'ng-apexcharts';
import { DashboardService } from '../dashboard.service';

import { element } from 'protractor';
import {
  chartHistoryDurationElements,
  IWatchListChart,
  chartColors,
  IEditionHourlyForReturn,
} from '@app/home/models/historyHourly.model';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  colors: any;
  toolbar: any;
};

@Component({
  selector: 'comparison-chart',
  templateUrl: './comparisonChart.component.html',
  providers: [NgsRevealConfig],
})
export class ComparisonChartComponent implements OnInit {
  public chartOptions: Partial<ChartOptions> = {};
  @Input() editions: string[] = [];
  duration: any = chartHistoryDurationElements;
  selectedDuration: string;
  UiReady: boolean = true;
  public series: ApexAxisChartSeries;
  public chart: ApexChart;
  public colors: string[];
  public dataLabels: ApexDataLabels;
  public markers: ApexMarkers;
  public title: ApexTitleSubtitle;
  public fill: ApexFill;
  public yaxis: ApexYAxis;
  public xaxis: ApexXAxis;
  public tooltip: ApexTooltip;
  public noData: ApexNoData = {
    text: 'Sorry,, there is no chart line to show',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#fff',
      fontSize: '14px',
      fontFamily: undefined,
    },
  };
  itemsToGetChart: IWatchListChart[] = [];
  @ViewChild('chart', { static: false }) chartF: ChartComponent;
  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
    this.selectedDuration = 'FiveDays';
    this.initilaizeChart();
  }
  ngOnInit() {
    this.spinner.show('loading');
    this.getWatchChartInitial();
    this.addToWatchChartListener();
    this.removeFromWatchChartListener();
    this.rColors = [];
  }
  removeFromChart(edition: IWatchListChart) {
    this.removeFromChartAction(edition);
    this.dashboardService.removeFromWatchChartEvent.next(edition.editionReferenceId);
  }
  removeFromChartAction(edition: IWatchListChart) {
    this.itemsToGetChart = this.itemsToGetChart.filter((t) => t.editionReferenceId != edition.editionReferenceId);
    this.chartOptions.series = this.chartOptions.series.filter((f) => f.name != edition.editionName);
    this.rColors = this.rColors.filter((g) => g != edition.color);
    this.dashboardService.removeWatchListChart(edition.editionReferenceId).subscribe();
    if (this.itemsToGetChart.length == 0) {
      this.UiReady = false;
    }
  }
  removeFromWatchChartListener() {
    this.dashboardService.removeFromWatchChartEvent.subscribe((h) => {
      var item = this.itemsToGetChart.find((d) => d.editionReferenceId == h);
      if (!item) return;
      console.log('response remove listener', item);
      var edition: IWatchListChart = {
        editionName: item.editionName,
        editionReferenceId: item.editionReferenceId,
        color: item.color,
      };
      this.removeFromChartAction(edition);
    });
  }
  addToWatchChartListener() {
    this.dashboardService.addToWatchChart.subscribe((h) => {
      if (this.itemsToGetChart?.length > 4) {
        return;
      }
      if (this.itemsToGetChart.map((g) => g.editionReferenceId).includes(h.referenceId)) {
        return;
      }
      var colorToAdd = chartColors[this.itemsToGetChart?.length];
      for (let index = 0; index < chartColors.length; index++) {
        if (!this.rColors.find((f) => f == chartColors[index])) {
          colorToAdd = chartColors[index];
        }
      }
      this.rColors.push(colorToAdd);
      let item: IWatchListChart = {
        editionName: h.name,
        editionReferenceId: h.referenceId,
        color: colorToAdd,
      };
      // we should check if the edition contain items or not
      this.itemsToGetChart.push(item);
      this.selectDuration();
    });
  }

  getWatchChartInitial() {
    this.dashboardService.getWatchChart().subscribe((ed) => {
      this.itemsToGetChart = ed;
      console.log(ed);

      this.itemsToGetChart.forEach((element, index) => {
        console.log('' + index, element);
        element.color = chartColors[index];
        this.rColors.push(chartColors[index]);
      });

      this.dashboardService
        .getHourlyChartData(
          this.itemsToGetChart.map((f) => f.editionReferenceId),
          'FiveDays'
        )
        .subscribe((g) => {
          this.editionHourly = g;
          // this.chartF.updateSeries(this.getSeries(g), true);
          var itemsToShow: IEditionHourlyForReturn[] = [];
          this.itemsToGetChart
            .map((f) => f.editionReferenceId)
            .forEach((element) => {
              itemsToShow.push(g.find((r) => r.editionReferenceId == element));
            });
          this.initHourlyChartData(itemsToShow);
          if (this.itemsToGetChart.length == 0) {
            this.UiReady = false;
            this.series = [];
          }
          this.spinner.hide('loading');
        });
    });
  }
  editionHourly: IEditionHourlyForReturn[];
  rColors: string[] = [chartColors[0]];
  initilaizeChart() {
    var that = this;
    this.chartOptions.chart = {
      type: 'area',
      stacked: false,
      height: 350,

      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      },
      toolbar: {
        show: true,
        autoSelected: 'zoom',
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
        },
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
        },
      },
    };
    this.series = [
      {
        name: 'Consumption',
        data: [],
      },
    ];
    this.rColors = chartColors;
    this.chartOptions.dataLabels = {
      enabled: false,
    };
    this.chartOptions.markers = {
      size: 0,
    };
    this.chartOptions.title = {
      align: 'left',
      style: {
        color: '#fff',
      },
    };
    this.chartOptions.annotations = {
      position: 'top',
    };
    this.chartOptions.fill = {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 0.5,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    };
    this.chartOptions.yaxis = {
      labels: {},
    };
    this.chartOptions.xaxis = {
      floating: false,
      tooltip: {
        formatter: function (val: any) {
          var date = new Date(val);
          return date.toLocaleString();
        },
      },

      labels: {
        // formatter: function (val: string, timeStamp: number) {
        //   var time = that.getDateWithTimeFormat(timeStamp);
        //   return time;
        // },
        style: {
          colors: ['#fff'],
        },
        hideOverlappingLabels: true,

        show: true,
        datetimeUTC: true,
        format: 'dd MMM yyyy',
      },
      type: 'datetime',
    };
  }
  convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  }
  getSeries(items: IEditionHourlyForReturn[]): ApexAxisChartSeries {
    return items.map((item) => {
      var h: { name: string; data: any } = {
        name: item.editionName,
        data: item.values.map((e) => {
          var t: { x: number; y: number } = {
            x: e.timeFrame * 1000,
            y: e.averagePrice,
          };
          return t;
        }),
      };
      return h;
    });
  }
  public initHourlyChartData(items: IEditionHourlyForReturn[]): void {
    // let ts2 = 1484418600000;
    // let dates = [];
    // for (let i = 0; i < 120; i++) {
    //   ts2 = ts2 + 86400000;
    //   dates.push([ts2, dataSeries[1][i].value]);
    // }
    // var chartoptions: Partial<ChartOptions> = {};
    var that = this;
    var itemToChart: ApexAxisChartSeries | { name: string; data: any }[] = [];

    var chartItems = items.map((item) => {
      console.log(item);
      var h: { name: string; data: any } = {
        name: item.editionName,
        data: item.values.map((e) => {
          var t: { x: number; y: number } = {
            x: e.timeFrame * 1000,
            y: e.averagePrice,
          };
          return t;
        }),
      };
      return h;
    });

    this.chartOptions.series = chartItems;

    this.chartOptions.tooltip = {
      shared: true,
      x: {
        formatter: function (val) {
          var time = that.getTimeFromDate(val);
          return time;
        },
      },
      y: {
        //  formatter: function (val) {
        //    var time = new Date(val).getTime().toString();
        //    console.log(time);
        //    return time;
        //  }
      },
    };
    // return chartoptions;
  }
  public initDailyChartData(items: IEditionHourlyForReturn[]): void {
    // let ts2 = 1484418600000;
    // let dates = [];
    // for (let i = 0; i < 120; i++) {
    //   ts2 = ts2 + 86400000;
    //   dates.push([ts2, dataSeries[1][i].value]);
    // }
    // var chartoptions: Partial<ChartOptions> = {};

    var that = this;
    var chartItems = items.map((item) => {
      var h: { name: string; data: any } = {
        name: item.editionName,
        data: item.values.map((e) => {
          var t: { x: number; y: number } = {
            x: e.timeFrame * 1000,
            y: e.averagePrice,
          };
          return t;
        }),
      };
      return h;
    });
    this.chartOptions.series = chartItems;

    this.chartOptions.tooltip = {
      shared: true,
      x: {
        formatter: function (val) {
          var date = new Date(val);
          return date.toLocaleDateString();
        },
      },
      y: {
        //  formatter: function (val) {
        //    var time = new Date(val).getTime().toString();
        //    console.log(time);
        //    return time;
        //  }
      },
    };
    // return chartoptions;
  }

  selectDuration() {
    this.spinner.show('loading');
    if (this.selectedDuration == 'Day' || this.selectedDuration == 'FiveDays')
      this.dashboardService
        .getHourlyChartData(
          this.itemsToGetChart.map((f) => f.editionReferenceId),
          this.selectedDuration
        )
        .subscribe((g) => {
          this.editionHourly = g;
          // this.chartF.updateOptions(this.initHourlyChartData(g), false, true, true);
          this.initHourlyChartData(g);
          if (this.itemsToGetChart.length > 0) this.UiReady = true;
          else this.UiReady = false;
          this.spinner.hide('loading');
        });
    else {
      this.dashboardService
        .getDailyChartData(
          this.itemsToGetChart.map((f) => f.editionReferenceId),
          this.selectedDuration
        )
        .subscribe((g) => {
          this.editionHourly = g;
          // this.chartF.updateOptions(this.initDailyChartData(g), false, true, true);
          var itemsToShow: IEditionHourlyForReturn[] = [];
          this.itemsToGetChart
            .map((f) => f.editionReferenceId)
            .forEach((element) => {
              itemsToShow.push(g.find((r) => r.editionReferenceId == element));
            });
          this.initDailyChartData(itemsToShow);
          if (this.itemsToGetChart.length > 0) this.UiReady = true;
          else this.UiReady = false;
          this.spinner.hide('loading');
        });
    }
  }

  getTimeFromDate(timestamp: number) {
    var date = new Date(timestamp);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    return this.pad(hours) + ':' + this.pad(minutes) + ':' + this.pad(seconds);
  }
  updateCart() {}
  pad(num: number) {
    return ('0' + num).slice(-2);
  }
}
