<div>
  <div class="page-hero" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title">
        <h2 class="text-md text-highlight">Networking</h2>
        <small class="text-muted">All About Comunication.. Sharing Ideas.. Agreements,,</small>
      </div>
    </div>
  </div>
  <div class="page-content" id="page-content">
    <div class="padding">
      <div class="row">
        <div class="col-sm-2 col-md-2 d-none d-lg-block">
          <div #spacer></div>
          <!-- <div stickyThing marginTop="30" [spacer]="spacer" class="sidenav my-3"> -->
          <div class="sidenav my-3">
            <nav class="nav-active-text-primary">
              <ul class="nav">
                <li [routerLinkActive]="['activeTopics']">
                  <a (click)="globalClicked()" [routerLink]="['./global']">
                    <span class="nav-icon">
                      <i class="fas fa-globe-americas"></i>
                    </span>
                    <span class="nav-text">Global</span>
                  </a>
                </li>
                <li [routerLinkActive]="['activeTopics']">
                  <a (click)="mypostClicked()" [routerLink]="['./mypost']">
                    <span class="nav-icon">
                      <i class="fas fa-comment"></i>
                    </span>
                    <span class="nav-text">My Posts</span>
                  </a>
                </li>
                <li class="nav-header d-flex">
                  <div class="py-2 text-muted">Topics</div>
                  <!-- <div class="py-2 text-muted">Topics</div> -->
                  <span class="flex"></span>
                  <div *ngIf="topicLoading" class="loading"></div>
                  <div class="dropdown dropleft">
                    <button
                      (click)="addNewTopicBar()"
                      data-toggle="dropdown"
                      class="btn btn-sm btn-icon no-shadow no-bg"
                    >
                      <i class="fas fa-plus"></i>
                    </button>
                    <div style="width: 300px !important;" class="dropdown-menu w">
                      <div class="p-3">
                        <ng-template #fileName>
                          <div class="input-group">
                            <select
                              style="width: 200px !important;"
                              class="js-example-basic-multiple js-states form-control"
                              id="id_label_multiple2"
                            ></select>
                            <span class="input-group-append">
                              <button
                                (click)="addNewTopic()"
                                class="btn btn-white no-shadow btn-sm"
                                type="button"
                                id="newBtn"
                              >
                                <i class="fas fa-check"></i>
                              </button>
                            </span>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="mb-1" *ngFor="let item of topics" [ngClass]="{ activeTopics: isSelectedTopics(item) }">
                  <div class="row">
                    <div class="col-12">
                      <button
                        (click)="removeTopic(item)"
                        class="btn btn-white no-shadow btn-sm"
                        type="button"
                        id="newBtn"
                      >
                        <i class="fas fa-times"></i>
                      </button>
                      <a (click)="addToNetworkFilter(item.name)" class="link">
                        <span class="nav-text name">{{ item.name }}</span>
                      </a>
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="row">
                    <div class="col-12">
                      <div *ngIf="!addNewTopicBarShowed; else fileName"></div>
                      <a (click)="addNewTopicBar()" *ngIf="!addNewTopicBarShowed" class="link">
                        <span class="nav-text name py-2 text-muted"> <i class="fas fa-plus"></i> Add New</span>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-10">
          <div class="d-block d-md-none">
            <div class="row row-sm">
              <div class="col-6">
                <button
                  style="width: 100%;"
                  (click)="globalClicked()"
                  [routerLink]="['./global']"
                  class="btn w-sm mb-1 btn-outline-primary"
                >
                  Global
                </button>
              </div>
              <div class="col-6">
                <button
                  (click)="mypostClicked()"
                  [routerLink]="['./mypost']"
                  style="width: 100%;"
                  class="btn w-sm mb-1 btn-outline-primary"
                >
                  My Posts
                </button>
              </div>
            </div>
          </div>
          <div class="row row-sm mb-3 backgroundLight d-block d-md-none">
            <div class="col-12">
              <div class="row row-sm">
                <div class="col-2 pt-2">
                  <button
                    (click)="showAddNewTopicSectionMobileInit()"
                    class="btn btn-md btn-raised btn-wave btn-icon btn-rounded mb-2 green text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-plus"
                    >
                      <line x1="12" y1="5" x2="12" y2="19"></line>
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                    </svg>
                  </button>
                </div>
                <div class="col-10">
                  <ul *ngIf="!showAddNewTopicSectionMobile" style="padding-inline-start: 0px;" class="pt-2">
                    <li
                      [ngClass]="{ activeTopics: isSelectedTopics(item) }"
                      class="btn-group btn-group-toggle mx-1"
                      data-toggle="buttons"
                      *ngFor="let item of topics; let i = index"
                    >
                      <div class="input-group">
                        <span class="input-group-append">
                          <button (click)="removeTopic(item)" type="button" class="btn btn-light">
                            <i class="fas fa-times"></i>
                          </button>
                        </span>
                        <button
                          [ngClass]="isSelectedTopics(item) ? 'btn-success' : 'btn-secondary'"
                          (click)="addToNetworkFilter(item.name)"
                          type="button"
                          class="btn"
                        >
                          {{ item.name }}
                        </button>
                      </div>
                    </li>
                  </ul>
                  <div *ngIf="showAddNewTopicSectionMobile" class="p-3">
                    <div class="input-group">
                      <select
                        style="width: 200px !important; z-index: 99999; overflow: visible;"
                        class="js-example-basic-multiple js-states form-control"
                        id="id_label_multiple2_mobile"
                      ></select>
                      <span class="input-group-append">
                        <button
                          (click)="addNewTopic('mobile')"
                          class="btn btn-white no-shadow btn-sm"
                          type="button"
                          id="newBtn"
                        >
                          <i class="fas fa-check"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="selectedItems.length > 0" class="row row-sm p-2 mb-3 backgroundLight">
            <div class="col-lg-8 col-sm-8 pl-4">
              <div class="row row-sm">
                <span class="mr-1">Result Topics : </span>
                <div class="mr-2" *ngFor="let topic of selectedItems">
                  <span class="badge badge-success text-sm text-uppercase">#{{ topic }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-sm pb-3">
            <div class="col-12">
              <div class="row row-sm">
                <div style="min-width: 70px;" class="col-1 d-none d-lg-block">Sort By:</div>
                <div style="min-width: 135px;" class="col-2">
                  <a (click)="changeSort('hotPost')" class="boldText" [ngClass]="{ sortActive: sortType == 'hotPost' }">
                    HOT POSTS <i class="fas fa-fire"></i
                  ></a>
                </div>
                <div style="min-width: 144px;" class="col-1">
                  <a (click)="changeSort('newest')" class="boldText" [ngClass]="{ sortActive: sortType == 'newest' }"
                    >NEWEST <i class="fas fa-arrow-down"></i
                  ></a>
                </div>
                <div class="flex d-none d-lg-block"></div>
                <div class="col-3">
                  <button
                    [routerLink]="['./newPost']"
                    class="btn btn-sm btn-block box-shadows btn-rounded gd-danger text-white"
                    id="btn-new-mail"
                  >
                    New Post
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-sm">
            <div class="col-12 p-3 networkingBackground">
              <router-outlet></router-outlet>
              <!-- <app-post></app-post>
              <div class="replyPost">
                <ul class="timeline">
                  <li class="timeline-inverted">
                    <div class="timeline-badge"><i class="glyphicon glyphicon-check"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                  <li class="timeline-inverted">
                    <div class="timeline-badge warning"><i class="glyphicon glyphicon-credit-card"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                  <li class="timeline-inverted">
                    <div class="timeline-badge danger"><i class="glyphicon glyphicon-credit-card"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                  <li class="timeline-inverted">
                    <div class="timeline-badge info"><i class="glyphicon glyphicon-floppy-disk"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                  <li class="timeline-inverted">
                    <div class="timeline-badge info"><i class="glyphicon glyphicon-floppy-disk"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                  <li class="timeline-inverted">
                    <div class="timeline-badge info"><i class="glyphicon glyphicon-floppy-disk"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                  <li class="timeline-inverted">
                    <div class="timeline-badge success"><i class="glyphicon glyphicon-thumbs-up"></i></div>
                    <div class="timeline-panel">
                      <app-post-reply></app-post-reply>
                    </div>
                  </li>
                </ul>
                <div class="card gedf-card " [ngsReveal]="{ reset: false }">
                  <div class="card-body">
                    <div class="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
                      <div class="form-group">
                        <label class="sr-only" for="message">post</label>
                        <textarea class="form-control" id="message" rows="3"
                          placeholder="What are you thinking?"></textarea>
                      </div>
                    </div>
                    <div class="btn-toolbar justify-content-between">
                      <div class="btn-group">
                        <button type="submit" class="btn btn-primary">reply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
