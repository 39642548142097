export class WatchList {
  referenceId: string;
  editions: WatchListItem[];
}

export class WatchListItem {
  referenceId: string;
  name: string;
  open: number;
  close: number;
  highest: string;
  lowest: string;
  currentAverage: string;
  gradeAverage: string[];
  ratio: number;
  brandName: string;
  categoryName: string;
  attributes: string[];
  isExistInWatchChart: boolean;
  isActive: boolean;
}
