import { Injectable, ɵbypassSanitizationTrustResourceUrl } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  ActivatedRoute,
  CanActivateChild,
} from '@angular/router';

import { Logger } from '@core';
import { CredentialsService } from './credentials.service';
import { AuthService } from './auth.service';
import { Observable, iif, of } from 'rxjs';
import { tap, map, concatMap, switchMap, filter, take } from 'rxjs/operators';
import { UserService } from '@app/user/userService.service';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService, private userService: UserService) {}

  waitForHandleAuthCallbackToComplete(): Observable<boolean> {
    return this.auth.handleAuthCallbackComplete$.pipe(
      filter((complete) => complete),
      take(1)
    );
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean | UrlTree> | boolean {
    return this.waitForHandleAuthCallbackToComplete().pipe(
      switchMap(() =>
        this.auth.isAuthenticated$.pipe(
          tap((loggedIn) => {
            if (!loggedIn) {
              this.auth.login(state.url);
            } else {
              this.checkUserStatus(this.auth.getTokenSilently$());
            }
          })
        )
      )
    );
  }

  checkUserStatus(token: Observable<string>) {
    token.subscribe((f) => {
      var tokenParameters = this.auth.parseJwt(f);
      // this.cookieService.set("userInfo", JSON.stringify(tokenParameters));
      if (!tokenParameters['https://mobilityintel.com/businessName']) {
        this.userService.getUser$(false).subscribe((g) => {
          if (!g.business?.name) {
            this.router.navigate(['/completeRegistration']);
          }
        });
      }
    });
  }
}
