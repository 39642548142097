import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, from } from 'rxjs';
import { retry, catchError, retryWhen, take, delay, concatMap, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthService } from '@app/auth/auth.service';
import { Time } from '@angular/common';
import { IUser } from './user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}
  userServiceRoute = '/users';
  currentUser: IUser;
  lastUpdate: Time;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  getUser$(withCache: boolean = true): Observable<IUser> {
    console.log('user', this.currentUser);
    if (withCache)
      if (this.currentUser) {
        return of(this.currentUser);
      }
    return this.http.get<IUser>(environment.serverUrl + this.userServiceRoute).pipe(
      map((body: any) => body),
      tap((user) => (this.currentUser = user)),
      shareReplay(5),
      catchError(() => of('Error, could not load watchlist from api'))
    );
  }
}
