import { Component, OnInit } from '@angular/core';
import { IPost } from '../models/post.model';
import { NetworkingService } from '../networking.service';

@Component({
  selector: 'app-my-posts',
  templateUrl: './my-posts.component.html',
  styleUrls: ['./my-posts.component.scss'],
})
export class MyPostsComponent implements OnInit {
  postsToShow: IPost[] = [];
  constructor(private networkingService: NetworkingService) {}
  showLoading: boolean = true;
  ngOnInit(): void {
    this.networkingService.getPosts$().subscribe((g) => {
      this.postsToShow = g.result;
      this.showLoading = false;
    });
  }
}
