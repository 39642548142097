import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { IEditionSearchForReturn } from '@app/home/models/pricing.model';

@Component({
  selector: '[price-row]',
  templateUrl: './priceRow.component.html',
  providers: [NgsRevealConfig],
})
export class PriceRowComponent implements OnInit {
  @Input() matchResult: IEditionSearchForReturn;
  @Output() onRemoveMatchResult = new EventEmitter<IEditionSearchForReturn>();

  constructor(revealConfig: NgsRevealConfig, private spinner: NgxSpinnerService) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  loader = false;
  selectedIndex: number = 0;

  ngOnInit() {}

  isSingleMatch() {
    if (this.matchResult.confidence == 'Excellent' || this.matchResult.confidence == 'Approved')
      if (this.matchResult.matchedEditions.length > 0) return true;
  }
  isMultipleMatch() {
    if (this.matchResult.confidence == 'Good' || this.matchResult.confidence == 'Bad')
      if (this.matchResult.matchedEditions.length > 0) return true;
  }

  isNoMatch() {
    if (this.matchResult.matchedEditions.length == 0) return true;
  }

  onCorrectMatchSelected(index: number) {
    this.selectedIndex = index;
    this.matchResult.confidence = 'Approved';
  }

  removeMatchResult() {
    this.onRemoveMatchResult.emit(this.matchResult);
  }
}
