<table class="table table-theme v-middle m-0">
  <tbody>
    <tr [ngClass]="{ priceNotActive: item.hasOwnProperty('isActive') && !item.isActive }">
      <td *ngIf="showUpdateWatchList" style="width: 15px; text-align: center;" style="width: 5%;">
        <div>
          <label class="ui-check m-0 ui-check-rounded ui-check-md">
            <input type="checkbox" name="id" value="{{ item.referenceId }}" />
            <i></i>
          </label>
        </div>
      </td>
      <td>
        <div class="flex">
          <span class="item-title text-color bold">{{ getProductName(item.name) }}</span>
          <div class="item-except text-sm">
            <span class="text-color"> {{ getSpecValues(item.name) }}</span>
          </div>
          <div class="item-except text-sm">
            <span class="brand_color"> {{ item.brandName }}</span> /
            <span class="category_color">{{ item.categoryName }}</span>
          </div>
        </div>
      </td>
      <td style="width: 20%;">
        <span class="item-company ajax text-highlight font-weight-500"> ${{ item.currentAverage }} </span>
        <div [ngClass]="{ 'text-success': item.ratio >= 0, 'text-danger': item.ratio < 0 }" class="item-except text-sm">
          <span *ngIf="item?.ratio > 0">+</span>{{ calculateRatio(item.open, item.ratio) }} ({{ item.ratio }}%)
        </div>
      </td>
      <td style="width: 5%;">
        <span [ngClass]="{ 'text-success': item.ratio >= 0, 'text-danger': item.ratio < 0 }" class="h-1x">
          <strong style="font-weight: bolder; font-size: larger;">
            <i *ngIf="item?.ratio >= 0" class="fas fa-chevron-up"></i>
            <i *ngIf="item?.ratio < 0" class="fas fa-chevron-down"></i>
          </strong>
        </span>
      </td>
      <td style="width: 5%;">
        <span [ngClass]="{ 'text-white': true }" class="h-1x">
          <strong style="font-weight: bolder;">
            <a (click)="isExpandDetails = !isExpandDetails"> <i class="fas fa-dollar-sign"></i></a>
          </strong>
        </span>
      </td>
      <td style="width: 5%;">
        <span
          [ngClass]="{ 'text-primary': item.isExistInWatchChart, 'text-white': !item.isExistInWatchChart }"
          class="h-1x"
        >
          <strong style="font-weight: bolder; font-size: larger;">
            <a (click)="addToWatchListChart(item)"> <i class="fas fa-chart-line"></i></a>
          </strong>
        </span>
      </td>

      <td *ngIf="showUpdateWatchList" style="width: 5%;">
        <strong style="font-weight: bolder; font-size: larger;">
          <a (click)="removeFromWatchlist(item.referenceId)"> <i class="fas fa-trash-alt"></i></a>
        </strong>
      </td>
    </tr>
    <tr *ngIf="isExpandDetails">
      <td colspan="7">
        <div class="row row-sm">
          <div class="col-2">
            <div class="row row-sm" style="padding: 1px;">
              <div
                class="col-12 text-sm font-weight-500"
                style="background-color: #252f56; text-align: center; margin: 1px;"
              >
                Grade A
              </div>
              <div
                class="col-12 text-highlight font-weight-500"
                style="background-color: #589ede; text-align: center; margin: 1px;"
              >
                ${{ item.currentAverage }}
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row row-sm" style="padding: 1px;">
              <div
                class="col-12 text-sm font-weight-500"
                style="background-color: #252f56; text-align: center; margin: 1px;"
              >
                Grade B
              </div>
              <div
                class="col-12 text-highlight font-weight-500"
                style="background-color: #3372ad; text-align: center; margin: 1px;"
              >
                ${{ item.gradeAverage[0] }}
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row row-sm" style="padding: 1px;">
              <div
                class="col-12 text-sm font-weight-500"
                style="background-color: #252f56; text-align: center; margin: 1px;"
              >
                Grade C
              </div>
              <div
                class="col-12 text-highlight font-weight-500"
                style="background-color: #0f599e; text-align: center; margin: 1px;"
              >
                ${{ item.gradeAverage[1] }}
              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="row row-sm" style="padding: 1px;">
              <div
                class="col-12 text-sm font-weight-500"
                style="background-color: #252f56; text-align: center; margin: 1px;"
              >
                Grade D
              </div>
              <div
                class="col-12 text-highlight font-weight-500"
                style="background-color: #00396f; text-align: center; margin: 1px;"
              >
                ${{ item.gradeAverage[2] }}
              </div>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</table>
