import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, timer, from, Subject } from 'rxjs';
import {
  retry,
  catchError,
  retryWhen,
  take,
  delay,
  concatMap,
  map,
  takeUntil,
  shareReplay,
  mapTo,
  mergeMap,
  flatMap,
  tap,
  finalize,
} from 'rxjs/operators';
import { environment } from '@env/environment';
import { WatchListItem } from '../models/watchList.model';
import { IEditionHourlyForReturn, IWatchListChart, IWatchListChartResponse } from '../models/historyHourly.model';
import { IRatioLiveItem } from '../models/ratioLiveItems.model';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private http: HttpClient) {}
  public addToWatchChart: Subject<WatchListItem> = new Subject<WatchListItem>();
  public removeFromWatchChartEvent: Subject<string> = new Subject<string>();

  watchListServiceRoute = '/WatchList';
  editionsHourlyServiceRoute = '/EditionsHourly';
  editionsDailyServiceRoute = '/EditionsDaily';
  brandsDailyServiceRoute = '/BrandsDaily';
  editionsWatchServiceRoute = '/WatchCharts';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getWatchList$(): Observable<any> {
    return this.http.get<WatchListItem[]>(environment.serverUrl + this.watchListServiceRoute).pipe(
      map((body: any) => body.result[0].editions),
      shareReplay(1),
      catchError(() => of('Error, could not load watchlist from api'))
    );
  }

  getHourlyChartData(editionId: string[], timeFrameCriteria: string): Observable<IEditionHourlyForReturn[]> {
    let httpParams = editionId.reduce((p, id) => p.append('editionReferenceIds', id), new HttpParams());
    httpParams = httpParams.append('timeFrameCriteria', timeFrameCriteria);
    return this.http
      .get<WatchListItem[]>(environment.serverUrl + this.editionsHourlyServiceRoute, { params: httpParams })
      .pipe(
        map((body: any) => body),
        shareReplay(1),
        catchError(() => of('Error, could not load watchlist from api'))
      );
  }

  getDailyChartData(editionId: string[], timeFrameCriteria: string): Observable<IEditionHourlyForReturn[]> {
    let httpParams = editionId.reduce((p, id) => p.append('editionReferenceIds', id), new HttpParams());
    httpParams = httpParams.append('timeFrameCriteria', timeFrameCriteria);
    return this.http
      .get<WatchListItem[]>(environment.serverUrl + this.editionsDailyServiceRoute, { params: httpParams })
      .pipe(
        map((body: any) => body),
        shareReplay(1),
        catchError(() => of('Error, could not load watchlist from api'))
      );
  }
  getWatchChart(): Observable<IWatchListChart[]> {
    return this.http.get<IWatchListChartResponse>(environment.serverUrl + this.editionsWatchServiceRoute).pipe(
      map((body) => body.result[0].editions),
      shareReplay(1)
    );
  }

  postWatchListChart(editionId: string) {
    return this.http
      .post<any>(environment.serverUrl + this.editionsWatchServiceRoute + '/' + editionId, this.httpOptions)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not search from api'))
      );
  }

  removeWatchListChart(editionId: string) {
    return this.http
      .delete<any>(environment.serverUrl + this.editionsWatchServiceRoute + '/' + editionId, this.httpOptions)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not search from api'))
      );
  }
  generateRandomNumber() {
    var min = -2.0,
      max = 2.0,
      highlightedNumber = (Math.random() * (max - min) + min).toFixed(2);
    return Number(highlightedNumber);
  }

  getRatioHistory(date: string): Observable<IRatioLiveItem[]> {
    // return of([
    //   {
    //     brandName: 'Apple',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'Samsung',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'Lenovo',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'Hp',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'Sony',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'HTC',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'HTC',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'HTC',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    //   {
    //     brandName: 'HTC',
    //     brandReferenceId: '333',
    //     ratio: this.generateRandomNumber(),
    //   },
    // ]).pipe(delay(1000));
    var selectDate = '';
    if (date) selectDate = '?dayDateTime=' + date;
    return this.http.get<IRatioLiveItem[]>(environment.serverUrl + this.brandsDailyServiceRoute + selectDate).pipe(
      map((body) => body),
      shareReplay(1)
    );
  }
}
