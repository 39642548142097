<div class="card gedf-card" [ngsReveal]="{ reset: false }">
  <form [formGroup]="replyForm" (ngSubmit)="onSubmit()">
    <div class="card-body">
      <div class="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
        <div class="form-group">
          <label class="sr-only" for="tags">Tags</label>
          <select
            class="js-example-basic-multiple js-states form-control"
            id="id_label_multiple"
            multiple="multiple"
          ></select>
        </div>
      </div>
      <div class="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
        <div class="form-group">
          <label class="sr-only" for="message">post</label>
          <textarea
            formControlName="message"
            class="form-control"
            rows="3"
            placeholder="What are you thinking?"
          ></textarea>
          <div *ngIf="submitted && replyFormControles.message.errors" class="invalid-feedback">
            <div *ngIf="replyFormControles.message.errors.required">*Please enter message before sending</div>
          </div>
        </div>
      </div>
      <div class="btn-toolbar justify-content-between">
        <div class="btn-group">
          <button class="btn btn-primary">Post</button>
        </div>
      </div>
    </div>
  </form>
</div>
