<td *ngIf="isSingleMatch()" style="max-width: 200px;">
  <span class="item-title text-color h-1x bold">{{ matchResult.providedName }}</span>
</td>

<td *ngIf="isSingleMatch()" style="max-width: 250px;">
  <div class="item-except text-sm">
    <span class="bold small-font text-white"> A </span>
  </div>
</td>

<td *ngIf="isSingleMatch()" style="max-width: 250px;">
  <div class="item-except text-sm">
    <span class="item-title text-color h-1x bold">{{ matchResult.matchedEditions[selectedIndex].productName }}</span>
    <div class="item-except text-sm h-1x">
      <span class="brand_color"> {{ matchResult.matchedEditions[selectedIndex].brandName }}</span> /
      <span class="category_color">{{ matchResult.matchedEditions[selectedIndex].categoryName }}</span>
    </div>
  </div>
</td>

<td *ngIf="isSingleMatch()" style="max-width: 250px;">
  <div class="item-except text-sm">
    <span class="bold small-font text-white"> {{ matchResult.matchedEditions[selectedIndex].name }} </span>
  </div>
</td>

<td *ngIf="isSingleMatch()" style="max-width: 250px;">
  <div class="item-except text-sm">
    <span class="big-font bold text-success"> ${{ matchResult.matchedEditions[selectedIndex].currentAverage }} </span>
  </div>
</td>

<td
  *ngIf="isMultipleMatch()"
  colspan="5"
  class="no-print"
  style="
    padding: 0%;
    border-color: rgba(116, 116, 116, 0.849) !important;
    border-width: 1px;
    border-style: solid !important;
  "
>
  <div class="item-except text-sm p-3">
    <div class="item-except h-2x">
      <span class="text-color bold"> No clear results were found for the provided product</span> :
      <span class="category_color bold" style="font-size: large;">{{ matchResult.providedName }}</span>
    </div>
    <div class="item-except text-sm h-1x">
      <span class="text-color"> Please click approve to keep only the correct match, or click </span>
      <span>
        <a (click)="removeMatchResult()" style="color: #f54394; font-size: 12px;" class="bold text-sm">Remove</a>
      </span>
      <span class="text-color"> if none of them are correct match</span>
    </div>
  </div>

  <div class="item-except text-sm pt-3 pb-1">
    <table class="table table-theme table-row v-middle" style="padding-left: 3%; padding-right: 3%;">
      <tbody>
        <tr
          *ngFor="let matchEdition of matchResult.matchedEditions; let i = index"
          multiMatchPriceRow-row
          [ngClass]="{ priceNotActive: matchEdition.hasOwnProperty('isActive') && !matchEdition.isActive }"
          [matchEdition]="matchEdition"
          [index]="i"
          (onCorrectMatchSelected)="onCorrectMatchSelected($event)"
        ></tr>
      </tbody>
    </table>
    <div class="item-except text-sm h-2x p-3">
      <span class="text-color"
        >if you can't find the correct value, Please try using different keywords in the provided file</span
      >
    </div>
  </div>
</td>

<td *ngIf="isNoMatch()" colspan="5" class="no-print" style="padding: 0%;">
  <div class="item-except text-sm p-3">
    <div class="item-except h-2x">
      <span class="text-color bold"> No results were matched with the provided product</span> :
      <span class="category_color bold" style="font-size: large;">{{ matchResult.providedName }}</span>
    </div>
    <div class="item-except text-sm h-1x">
      <span class="text-color"> Please try using different keywords</span>
    </div>
  </div>
</td>
