import { Component, OnInit } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { IRatioLiveItem } from '@app/home/models/ratioLiveItems.model';

@Component({
  selector: 'faq-main',
  templateUrl: './faq.component.html',
  providers: [NgsRevealConfig],
})
export class FaqComponent implements OnInit {
  constructor(revealConfig: NgsRevealConfig, private spinner: NgxSpinnerService) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }

  loaded: boolean = true;

  ngOnInit() {}
}
