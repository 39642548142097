import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { error } from 'jquery';
import { IDeviceCheckForReturn } from '../models/deviceCheck.model';
import { DeviceCheckService } from './deviceCheck.Service';

@Component({
  selector: 'app-device-check',
  templateUrl: './device-check.component.html',
  styleUrls: ['./device-check.component.scss'],
})
export class DeviceCheckComponent implements OnInit {
  deviceCheckValue: string = '';
  deviceCheckForReturn: IDeviceCheckForReturn;
  isProcessing: boolean = false;
  isExceedLimit: boolean = false;

  constructor(private deviceCheckService: DeviceCheckService) {}

  ngOnInit(): void {}

  onDeviceCheck() {
    this.isProcessing = true;
    this.isExceedLimit = false;
    this.deviceCheckService.getDeviceCheckById$(this.deviceCheckValue).subscribe(
      (f) => {
        this.deviceCheckForReturn = f;
      },
      (x) => {
        this.deviceCheckForReturn = null;
        if (x.error.errors[0].code === 2224) {
          this.isExceedLimit = true;
          this.isProcessing = false;
        }
      },
      () => {
        this.isProcessing = false;
      }
    );
  }
}
