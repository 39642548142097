import { Component, Input, OnInit } from '@angular/core';
import { IComments } from '../models/post.model';

@Component({
  selector: 'app-post-reply',
  templateUrl: './post-reply.component.html',
  styleUrls: ['./post-reply.component.scss'],
})
export class PostReplyComponent implements OnInit {
  @Input() data: IComments = new IComments();

  constructor() {}

  ngOnInit(): void {
    console.log(this.data);
  }
}
