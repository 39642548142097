<!-- <td>
  <div class="item-except">
    <span class="brand_color"> {{ rowData.provider}}</span>
  </div>
</td>

<td>
  <span class="item-title text-color h-1x bold">{{ rowData.gradeName }}</span>
</td>

<td>
  <span class="item-title text-color h-1x bold">{{ rowData.gradeValue }}</span>
</td> -->
<td>
  <span class="item-title text-color h-1x bold"> {{ rowData.provider }}</span>
</td>
<td>{{ rowData.gradeName }}</td>
<td>$ {{ rowData.gradeValue }}</td>
