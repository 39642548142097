import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IPost } from '../models/post.model';
import { NetworkingService } from '../networking.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
})
export class PostComponent implements OnInit {
  @Input() data: IPost = new IPost();
  @Output() onDelete = new EventEmitter<IPost>();

  constructor(private networkingService: NetworkingService) {}
  showDeletePost: boolean = false;
  ngOnInit(): void {
    var userJson = JSON.parse(localStorage.getItem('user'));
    if (this.data.user.email == userJson.email) {
      this.showDeletePost = true;
    }
  }

  removePost() {
    this.networkingService.deletePost(this.data.referenceId).subscribe((r) => {
      this.onDelete.emit(this.data);
    });
  }
  goToComments() {}
}
