import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, untilDestroyed } from '@core';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '@app/user/userService.service';

const log = new Logger('Login');

@Component({
  selector: 'logged-out',
  templateUrl: './loggedout.component.html',
})
export class LoggedOut implements OnInit, OnDestroy {
  constructor(private router: Router, private cookieService: CookieService, private userService: UserService) {
    userService.getUser$().subscribe((g) => {
      console.log(g);
    });
  }
  get username(): string | null {
    var name = this.userService?.currentUser?.fullName;
    if (!name) name = '';
    return name;
  }
  userPic() {
    var imgurl = this.cookieService.get('pic');
    if (!imgurl) return 'assets/logo4dark.png';
    return imgurl;
  }
  ngOnInit() {}

  ngOnDestroy() {}
}
