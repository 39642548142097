import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PagesRoutingModule } from './pages-routing.module';
import { CompleteRegistrationComponent } from './complateRegestration/completeRegistration.component';
import { NgsRevealModule, NgsRevealConfig } from 'ngx-scrollreveal';
import { ReactiveFormsModule } from '@angular/forms';
import { CompleteRegistrationService } from './complateRegestration/completeRegistration.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxMaskModule, IConfig } from 'ngx-mask';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PagesRoutingModule,
    NgsRevealModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot(maskConfigFunction),
  ],
  declarations: [CompleteRegistrationComponent],
  providers: [NgsRevealConfig, CompleteRegistrationService],
})
export class PagesModule {}
