<div [ngsReveal]="{ reset: false }" class="card post-item pb-2">
  <div class="card-header">
    <div class="d-sm-flex no-shrink">
      <a data-pjax-state="">
        <span class="w-56 avatar gd-primary" data-toggle-class="loading">
          <span class="avatar-status on b-white avatar-right"></span>
          <img *ngIf="data.user?.imageUrl" src="{{ data.user?.imageUrl }}" alt="." />
          <ngx-avatar *ngIf="!data.user?.imageUrl" name="{{ data.user?.fullName }}"></ngx-avatar>
        </span>
      </a>
      <div class="px-sm-4 my-3 my-sm-0 flex">
        <h2 class="text-md">
          {{ data.user?.fullName }} <small class="d-block">{{ data.user?.businessName }}</small>
        </h2>
        <p class="text-xs text-muted">{{ data.createdDate }}</p>
      </div>
      <button *ngIf="showDeletePost" class="close" aria-label="Close">
        <span [swal]="deleteSwal" aria-hidden="true">&times;</span>
      </button>
      <swal
        #deleteSwal
        title="This Post Will Be Deleted"
        text="you'll not be able undone"
        icon="question"
        [showCancelButton]="true"
        [focusCancel]="true"
        (confirm)="removePost()"
      >
      </swal>
    </div>
  </div>
  <div class="card-body">
    <p class="card-text">
      {{ data.description }}
    </p>
  </div>
  <div class="row row-sm">
    <div class="col-lg-8 col-sm-8 pl-4">
      <div class="row row-sm">
        <div class="mr-2" *ngFor="let topic of data.topics">
          <span class="badge badge-dark text-uppercase">#{{ topic.name }}</span>
        </div>
      </div>
    </div>
    <div class="flex"></div>
    <div class="col-12 d-block d-md-none mt-3">
      <div class="row row-sm">
        <div class="col-6 text-center">
          <a [routerLink]="['../details', { id: data.referenceId }]"><i class="fas fa-reply"> </i> </a>
        </div>
        <div class="col-6 text-center">
          <a [routerLink]="['../details', { id: data.referenceId }]"
            ><i class="fas fa-comment-alt"> </i> {{ data.comments?.length }}</a
          >
        </div>
      </div>
    </div>
    <div class="col-lg-1 col-sm-12 col-md-12 pb-2 d-none d-sm-block">
      <div class="row row-sm">
        <div class="col-lg-4 col-sm-6 col-md-6">
          <a [routerLink]="['../details', { id: data.referenceId }]"><i class="fas fa-reply-all"> </i> </a>
        </div>
        <div class="col-lg-6 col-sm-3 col-md-6">
          <a [routerLink]="['../details', { id: data.referenceId }]"
            ><i class="fas fa-comment-alt"> </i> {{ data.comments?.length }}</a
          >
        </div>
      </div>
    </div>
  </div>
</div>
