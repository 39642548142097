<div>
  <div class="page-hero page-container" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title">
        <h2 class="text-md text-highlight">FAQ</h2>
        <small class="text-muted">Frequently asked and answered</small>
      </div>
    </div>
  </div>
  <div class="page-content page-container" id="page-content">
    <div class="padding">
      <div class="row">
        <div class="col-sm-8 col-md-9">
          <p>
            <strong>Frequently asked</strong>
          </p>
          <div class="mb-4">
            <div [ngsReveal]="{ reset: false }" class="card mb-2">
              <div class="card-header no-border">
                <a data-toggle="collapse" data-target="#c_1"> <strong>Q:</strong> How to use Mobility intel system </a>
              </div>
              <div id="c_1" class="in b-t">
                <div class="card-body">
                  <div class="float-left mr-2"><span class="text-md w-32 avatar circle bg-success">A</span></div>
                  <p class="text-muted">
                    Id ipsum sed etiam mattis nec, purus imperdiet mauris, viverra est rhoncus malesuada malesuada
                    libero, mauris turpis sit lacus, molestie nisl, lacus ultrices a accumsan, amet cursus magna mi
                    ultricies dui nibh donec tincidunt orci, at egestas amet ante nisi euismod non amet leo cursus in
                    vitae nibh tincidunt enim ac enim integer vulputate vitae enim non
                  </p>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="card mb-2">
              <div class="card-header no-border">
                <a data-toggle="collapse" data-target="#c_2"> <strong>Q:</strong> How to monitor specific items </a>
              </div>
              <div id="c_2" class="b-t">
                <div class="card-body">
                  <div class="float-left mr-2"><span class="text-md w-32 avatar circle bg-info">A</span></div>
                  <p class="text-muted">
                    Tempor integer vitae pretium praesent laoreet bibendum sapien tincidunt risus tempus leo nulla
                    hendrerit quis consectetur nulla in tempus congue mattis ipsum aliquam platea parturient dolor
                    interdum quisque volutpat sed scelerisque imperdiet pulvinar leo in integer adipiscing et dignissim
                    sed vitae scelerisque in urna urna vulputate elit, nibh quam platea consequat at purus proin egestas
                    id sagittis, amet, eget quam sollicitudin facilisis accumsan duis sit nunc, at turpis metus nibh
                    neque, id egestas id pharetra cursus vitae lorem eget tellus pharetra, in enim massa, ipsum,
                    senectus enim, ut ac lectus scelerisque eu
                  </p>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="card mb-2">
              <div class="card-header no-border">
                <a data-toggle="collapse" data-target="#c_3">
                  <strong>Q:</strong> What is networking and communication
                </a>
              </div>
              <div id="c_3" class="b-t">
                <div class="card-body">
                  <div class="float-left mr-2"><span class="text-md w-32 avatar circle bg-warning">A</span></div>
                  <p class="text-muted">
                    Adipiscing faucibus lorem varius pulvinar quam ac pretium lacus semper morbi felis egestas porttitor
                    quis sit nec nisl augue dictum consectetur et, id sollicitudin dictum tortor, nunc eu porttitor
                    neque, eros, nisl porttitor ornare orci, ultrices sapien, sit ac eget nec tincidunt congue quam
                    turpis proin tempor, arcu tellus nullam quis enim convallis morbi nibh in lacus, faucibus at
                    placerat feugiat sit quis eleifend mauris, sit eget eu amet amet, ultrices eget arcu, vel
                    consectetur sit vitae dignissim eget metus sit odio tincidunt est risus suspendisse faucibus
                    imperdiet enim vestibulum id sit
                  </p>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="card mb-2">
              <div class="card-header no-border">
                <a data-toggle="collapse" data-target="#c_4"> <strong>Q:</strong> Where to buy for subscription </a>
              </div>
              <div id="c_4" class="b-t">
                <div class="card-body">
                  <div class="float-left mr-2"><span class="text-md w-32 avatar circle bg-success">A</span></div>
                  <p class="text-muted">
                    Vulputate pretium purus porttitor amet nisl amet gravida aliquam sed commodo at nibh at id natoque
                    eget ultricies amet neque felis facilisis ipsum auctor et volutpat ipsum aliquam sed duis mauris
                    elementum ante consectetur quisque nulla parturient dolor dolor amet aliquam ipsum, dolor elit urna
                    molestie turpis orci ultricies egestas nulla nullam id aliquam faucibus iaculis interdum tellus
                    feugiat suspendisse auctor turpis sit luctus tristique vel id erat dui et cras at faucibus consequat
                    blandit aenean viverra phasellus ullamcorper ligula lacus eleifend
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p>
            <strong>Submit ticket</strong>
          </p>
          <form>
            <div class="form-group row">
              <div class="col-sm-6">
                <label>Your name</label>
                <input type="text" class="form-control" placeholder="Name" required />
              </div>
              <div class="col-sm-6">
                <label>Email</label>
                <input type="email" class="form-control" placeholder="Enter email" required />
              </div>
            </div>
            <div class="form-group">
              <label>Subject</label>
              <select class="form-control c-select">
                <option></option>
                <option>Website Errors</option>
                <option>Product Services</option>
                <option>Login/Signup Problem</option>
              </select>
            </div>
            <div class="form-group">
              <label>Description</label>
              <textarea class="form-control" rows="6" placeholder="hmm.."></textarea>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
