import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { Shell } from '@app/shell/shell.service';
import { CompleteRegistrationComponent } from './complateRegestration/completeRegistration.component';
import { AuthGuard } from '@app/auth/authentication.guard';
const routes: Routes = [
  {
    path: 'completeRegistration',
    component: CompleteRegistrationComponent,
    //canActivate: [AuthGuard],
    data: { title: extract('Complete Registration') },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class PagesRoutingModule {}
