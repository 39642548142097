import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterByPipe',
})
export class FilterByPipe implements PipeTransform {
  transform(value: any[], args: string, arg2: Set<string>): any {
    console.log(arg2);
    if (!args) return value;
    let filter = args.toLowerCase();
    if (filter) {
      var returnedValues = [...arg2];
      returnedValues.push(...value.filter((term) => term.toLowerCase().indexOf(filter) !== -1));
      return returnedValues;
    } else return value;
  }
}
