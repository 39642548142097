import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable, of, throwError, timer, from, Subject } from 'rxjs';
import { IDeviceCheckForReturn } from '../models/deviceCheck.model';
import {
  retry,
  catchError,
  retryWhen,
  take,
  delay,
  concatMap,
  map,
  takeUntil,
  shareReplay,
  mapTo,
  mergeMap,
  flatMap,
  tap,
  finalize,
} from 'rxjs/operators';
import { environment } from '@env/environment';

const route = {
  DeviceCheck: (id: string) => '/DeviceCheck?deviceid=' + id,
};
@Injectable({
  providedIn: 'root',
})
export class DeviceCheckService {
  constructor(private http: HttpClient) {}
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getDeviceCheckById$(id: string): Observable<IDeviceCheckForReturn> {
    return this.http
      .get<IDeviceCheckForReturn>(environment.serverUrl + route.DeviceCheck(id), this.httpOptions)
      .pipe(map((body: any) => body));
  }
}
