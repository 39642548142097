import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CompleteRegistrationService } from './completeRegistration.service';
import { AuthService } from '@app/auth/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { PersonalInfo, BusinessInfo } from '@app/home/models/profile.model';

@Component({
  selector: 'complete-Registration',
  templateUrl: './completeRegistration.component.html',
})
export class CompleteRegistrationComponent implements OnInit {
  personalRegisterForm: FormGroup;
  businessRegisterForm: FormGroup;
  confrimForm: FormGroup;
  invalidSmsCode: boolean;
  invalidBusinessInfo: string[];
  submitted = false;

  constructor(
    revealConfig: NgsRevealConfig,
    private formBuilder: FormBuilder,
    private completeRegistrationService: CompleteRegistrationService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private cookieService: CookieService
  ) {
    revealConfig.duration = 500;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  personalInfo = false;
  verifyCodePage = false;
  ngOnInit() {
    this.personalRegisterForm = this.formBuilder.group({
      phone: ['', Validators.required],
      fullName: ['', Validators.required],
    });
    this.confrimForm = this.formBuilder.group({
      smsCode: ['', Validators.required],
    });

    this.businessRegisterForm = this.formBuilder.group({
      businessName: ['', Validators.required],
      website: ['', Validators.required],
      year: ['', Validators.required],
      vat: ['', Validators.required],
      businessField: ['Warehouse', Validators.required],
      businessStructure: ['SoleProprietorship', Validators.required],
      agreement: [false, Validators.requiredTrue],
    });
    this.authService.getUser$().subscribe((f) => {
      this.personalRegisterForm.setValue({
        fullName: f['name'],
        phone: '',
      });
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.personalRegisterForm.controls;
  }
  get b() {
    return this.businessRegisterForm.controls;
  }
  get c() {
    return this.confrimForm.controls;
  }
  logout() {
    this.authService.logout();
  }
  get username(): string | null {
    return this.cookieService.get('name');
  }

  userPic() {
    return this.cookieService.get('pic');
  }
  confirmPhone() {
    this.spinner.show();
    this.submitted = true;
    if (this.confrimForm.invalid) {
      this.spinner.hide();
      return;
    }

    var code = this.confrimForm.get('smsCode').value;
    this.completeRegistrationService.verifySmsCode$(code).subscribe(
      (result) => {
        this.spinner.hide();
        this.submitted = false;
        this.personalInfo = true;
        this.verifyCodePage = false;
      },
      (error) => {
        this.spinner.hide();
        this.invalidSmsCode = true;
        this.submitted = false;
      }
    );
  }
  onSubmit() {
    this.spinner.show();

    this.submitted = true;

    // stop here if form is invalid
    if (this.personalRegisterForm.invalid) {
      this.spinner.hide();
      return;
    }

    // ///////////////
    // this.spinner.hide();
    //     this.submitted = false;
    //     this.verifyCodePage = true;
    //     this.personalInfo = false;
    //     return
    // ////////////////
    let person = new PersonalInfo();
    person.fullName = this.personalRegisterForm.get('fullName').value;
    person.phoneNumber = '+' + this.personalRegisterForm.get('phone').value;
    person.imageUrl = this.userPic();
    this.completeRegistrationService.postPersonalInfo$(person).subscribe(
      (result) => {
        this.spinner.hide();
        this.submitted = false;
        this.verifyCodePage = true;

        this.personalInfo = false;
      },
      (error) => {
        this.spinner.hide();
        this.submitted = false;
        this.personalInfo = false;
        ///TODO: send alert
      }
    );
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.personalRegisterForm.value., null, 4));
  }
  skipSms() {
    this.personalInfo = true;
    this.verifyCodePage = false;
  }
  onSubmitBusinessInfo() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.businessRegisterForm.invalid) {
      this.spinner.hide();
      return;
    }

    let business = new BusinessInfo();
    business.name = this.businessRegisterForm.get('businessName').value;
    business.website = this.businessRegisterForm.get('website').value;
    business.yearEstablish = this.businessRegisterForm.get('year').value;
    business.vat = this.businessRegisterForm.get('vat').value;
    business.ownershipType = this.businessRegisterForm.get('businessStructure').value;
    business.businessField = this.businessRegisterForm.get('businessField').value;

    this.completeRegistrationService.postBusinessInfo$(business).subscribe(
      (result) => {
        this.spinner.hide();
        this.router.navigate(['/home']);
      },
      (error) => {
        this.spinner.hide();
        this.submitted = false;
        console.log(error.error.errors);
        this.invalidBusinessInfo = error.error.errors.map(
          (f: { message: any; action: any; problem: any }) => f.message || f.problem + ' ' + f.action
        );
      }
    );
  }
}
