<td style="max-width: 200px;">
  <a [routerLink]="['/providerGrade', product.referenceId]">
    <span class="item-title text-color h-1x bold">{{ product.name }}</span>
  </a>
  <div class="item-except text-sm h-1x">
    <span class="brand_color"> {{ product.brandName }}</span> /
    <span class="category_color">{{ product.categoryName }}</span>
  </div>
</td>
<td style="max-width: 250px;">
  <div class="row row-sm">
    <div *ngFor="let attr of product.attributes" class="col-lg-6 col-sm-12">
      <span class="bold small-green-font d-none d-lg-block"> {{ attr.attributeName }}</span>
      <div class="item-except text-sm">
        <span class="bold small-font text-white"> {{ attr.attributeValue }} </span>
      </div>
      <!-- <div class="row row-sm">
        <div class="d-none d-lg-block col-lg-6 col-sm-1 col-xs-1">
          <span class="bold small-green-font"> {{ attr.attributeName }}</span>
        </div>
        <div class="col-lg-6 col-sm-12">
          <span class="bold small-font text-white"> {{ attr.attributeValue }} </span>
        </div>
      </div> -->
    </div>
  </div>
</td>
<td>
  <span class="d-none d-lg-block bold font12 small-green-font"> Price:</span
  ><span class="big-font bold text-white"> ${{ product.currentAverage }} </span>
  <div
    [ngClass]="{ 'text-success': product.ratio >= 0, 'text-danger': product.ratio < 0 }"
    class="item-except text-sm h-1x"
  >
    <span *ngIf="product.ratio > 0">+</span>{{ calculateRatio(product.currentAverage, product.ratio) }}
    {{ product.ratio }}%
  </div>
</td>

<td style="max-width: 40px;">
  <div class="row row-sm">
    <div *ngIf="loader" class="col-12 centeredLoading">
      <div class="loading"></div>
    </div>
    <div *ngIf="!product.isExistInWatchList && !loader" class="col-12 center">
      <a (click)="addToWatchList(product.referenceId)" style="color: #ed5fd6; font-size: 10px;" class="bold text-sm">
        <div class="row row-sm">
          <div class="col-12 text-center" style="font-size: 12px;">Add to</div>
        </div>
        <div class="row row-sm">
          <div class="col-12 text-center">Watchlist</div>
        </div>
      </a>
    </div>
    <div *ngIf="product.isExistInWatchList && !loader" class="col-12 center">
      <a (click)="removeFromWatchList(product.referenceId)" class="bold text-sm disabledLink">
        <div class="row row-sm">
          <div class="col-12 text-center" style="font-size: 12px;">Remove from</div>
        </div>
        <div class="row row-sm">
          <div class="col-12 text-center">Watchlist</div>
        </div>
      </a>
    </div>
  </div>
</td>
