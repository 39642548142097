import { Component, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../dashboard.service';
import { AuthService } from '@app/auth/auth.service';
import { map, finalize } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { WatchListItem } from '@app/home/models/watchList.model';
import { ProductsService } from '@app/home/products/products.service';

@Component({
  selector: 'watch-list',
  templateUrl: './watchList.component.html',
  providers: [NgsRevealConfig],
})
export class WatchListComponent implements OnInit {
  @Output() isExtend = new EventEmitter<boolean>();

  isLoading = false;
  /* UI variables */
  watchListUIItems: WatchListItem[] = [];
  watchListCat: string[] = [];
  watchListCatActive: boolean = false;
  searchTermWatchList: string;
  staticAlertClosed = false;
  uiExtend = false;
  showUpdateWatchList: boolean = false;
  showEmptyValue: boolean = false;
  /* process variables */
  watchListitems: WatchListItem[] = [];
  watchListCatFilterItems: WatchListItem[] = [];
  watchListCatSelectedValues: string[] = [];
  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    public auth: AuthService,
    private cookieService: CookieService,
    private productsService: ProductsService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }

  ngOnInit() {
    this.isLoading = true;
    this.getUserWatchList();
    var watchlistExtended = this.cookieService.get('watchListExtended');
    if (watchlistExtended == 'true') {
      this.uiExtend = true;
      this.isExtend.emit(true);
    } else {
      this.uiExtend = false;
      this.isExtend.emit(false);
    }
    this.dashboardService.removeFromWatchChartEvent.subscribe((h) => {
      this.watchListUIItems.find((j) => j.referenceId == h).isExistInWatchChart = false;
    });
  }
  /* Watch List */
  getUserWatchList() {
    this.spinner.show('watchList');
    this.dashboardService
      .getWatchList$()
      .pipe(
        map((ev) =>
          ev.map((h: { categoryName: string }) => {
            if (this.watchListCat.indexOf(h.categoryName) <= -1) this.watchListCat.push(h.categoryName);
            return h;
          })
        ),
        finalize(() => {
          this.spinner.hide('watchList');
          if (this.watchListUIItems.length == 0) this.showEmptyValue = true;
          this.isLoading = false;
        })
      )
      .subscribe((result: WatchListItem[]) => {
        if (this.watchListCat.length > 1) this.watchListCatActive = true;
        this.watchListUIItems = this.watchListitems = result;
      });
  }

  onDeleteItem(item: WatchListItem) {
    this.watchListUIItems = this.watchListUIItems.filter((r) => r.referenceId != item.referenceId);
    this.watchListitems = this.watchListitems.filter((r) => r.referenceId != item.referenceId);
    this.watchListCatFilterItems = this.watchListCatFilterItems.filter((r) => r.referenceId != item.referenceId);
    if (this.watchListUIItems.length == 0) {
      this.showEmptyValue = true;
    }
  }

  addToWatchListChart(edition: WatchListItem) {
    if (edition.isExistInWatchChart) {
      this.dashboardService.removeFromWatchChartEvent.next(edition.referenceId);
      console.log('send remove listener', edition.referenceId);

      return;
    }
    if (this.watchListitems.filter((g) => g.isExistInWatchChart == true).length >= 5) {
      return;
    }
    edition.isExistInWatchChart = true;
    this.dashboardService.postWatchListChart(edition.referenceId).subscribe(
      (g) => {
        this.dashboardService.addToWatchChart.next(edition);
      },
      (error) => {
        edition.isExistInWatchChart = false;
      }
    );
  }

  extendWatchList() {
    this.isExtend.emit(true);
    this.uiExtend = true;
    this.cookieService.set('watchListExtended', 'true');
  }

  reduceWatchList() {
    this.isExtend.emit(false);
    this.uiExtend = false;
    this.cookieService.set('watchListExtended', 'false');
  }

  watchlistCatFilter(event: any, itemvalue: string) {
    var that = this;
    this.searchTermWatchList = '';
    if (event.currentTarget.checked) this.watchListCatSelectedValues.push(itemvalue);
    else {
      this.watchListCatSelectedValues = this.watchListCatSelectedValues.filter((item) => item !== itemvalue);
      if (this.watchListCatSelectedValues.length == 0) {
        this.resetWatchListFilters();
        return;
      }
    }
    this.watchListCatFilterItems = this.watchListUIItems = this.watchListitems.filter(function (tag) {
      return that.watchListCatSelectedValues.indexOf(tag.categoryName) >= 0;
    });
  }

  searchWatchlist(): void {
    let term = this.searchTermWatchList.toLowerCase();
    var filterSide;
    if (this.watchListCatFilterItems.length > 0) {
      filterSide = this.watchListCatFilterItems;
    } else filterSide = this.watchListitems;
    if (term)
      this.watchListUIItems = filterSide.filter(function (tag) {
        return tag.name.toLowerCase().indexOf(term) >= 0 || tag.brandName.toLowerCase().indexOf(term) >= 0;
      });
    else this.watchListUIItems = filterSide;
    if (this.watchListUIItems.length == 0) {
      this.showEmptyValue = true;
    } else this.showEmptyValue = false;
  }

  editWatchList() {
    this.showUpdateWatchList = true;
    this.resetWatchListFilters();
  }

  saveWatchListUpdate() {
    this.showUpdateWatchList = false;
  }
  removeFromWatchlist(refId: string) {
    this.spinner.show('watchList');
    this.productsService.removeFromWatchList$(refId).subscribe((g) => {
      this.spinner.hide('watchList');
      this.dashboardService.removeFromWatchChartEvent.next(refId);
      this.watchListUIItems = this.watchListUIItems.filter((r) => r.referenceId != refId);
      this.watchListitems = this.watchListitems.filter((r) => r.referenceId != refId);
      this.watchListCatFilterItems = this.watchListCatFilterItems.filter((r) => r.referenceId != refId);
      if (this.watchListUIItems.length == 0) {
        this.showEmptyValue = true;
      }
    });
  }

  resetWatchListFilters() {
    this.showEmptyValue = false;
    this.watchListUIItems = this.watchListitems;
    this.watchListCatFilterItems = [];
  }
  getProductName(name: string) {
    return name.split(':')[0];
  }
  getSpecValues(name: string) {
    return name.split(':')[1];
  }
  calculateRatio(price: number, ratio: number) {
    return ((ratio / 100) * price).toFixed(2);
  }
}
