<div class="layout-row">
  <div id="aside" class="page-sidenav no-shrink bg-light nav-dropdown fade sticky" aria-hidden="true">
    <div class="sidenav h-100 modal-dialog bg-light">
      <app-aside></app-aside>
    </div>
  </div>
  <div id="main" class="layout-column flex bg-body">
    <div id="header" class="page-header" data-class="bg-body">
      <app-header></app-header>
    </div>
    <div id="content" class="flex">
      <div>
        <div class="page-content" id="page-content">
          <router-outlet></router-outlet>
        </div>
      </div>
      <!-- ############ Main END-->
    </div>
    <!-- <div class="d-block d-md-none">
      <div
        style="position: fixed; bottom: 0px; width: 100%; background-color: rgb(38, 38, 56);"
        class="row row-sm btn-group p-1"
      >
        <div [routerLinkActive]="['active']" class="col-4 text-center">
          <a [routerLink]="['/home']" style="font-size: 30px;" class="text-primary text-center">
            <i class="fas fa-home"></i>
          </a>
        </div>
        <div [routerLinkActive]="['active']" class="col-4 text-center">
          <a [routerLink]="['/products']" style="font-size: 30px;" class="text-primary">
            <i class="fas fa-search-dollar"></i>
          </a>
        </div>
        <div [routerLinkActive]="['active']" class="col-4 text-center">
          <a [routerLink]="['/networking']" style="font-size: 30px;" class="text-primary">
            <i class="fas fa-comments-dollar"></i>
          </a>
        </div>
      </div>
    </div> -->
    <!-- ############ Footer START-->
    <div id="footer" class="page-footer hide">
      <div class="d-flex p-3">
        <span class="text-sm text-muted flex">&copy; Copyright. mobilityintel.com</span>
        <div class="text-sm text-muted">Version 1.0.0</div>
      </div>
    </div>
    <!-- ############ Footer END-->
  </div>
</div>
