<div style="min-height: 512px;" [ngsReveal]="{ reset: false }" class="card pb-3">
  <ngx-spinner
    [name]="'ratioLive'"
    [fullScreen]="false"
    bdColor="rgba(2, 4, 3, 0.21)"
    type="ball-grid-pulse"
    size="default"
  >
    <p style="margin-top: 45px; color: white;">Loading...</p>
  </ngx-spinner>
  <div class="p-3-4">
    <div class="d-flex">
      <div>
        <div class="text-white text-md text-highlight">Market Ratio (Live)</div>
      </div>
      <span class="flex"></span>
    </div>
  </div>
  <div class="row row-sm">
    <div class="col">
      <perfect-scrollbar class="collapesedWatchlist">
        <table class="table table-theme v-middle m-0 pl-4">
          <tbody>
            <tr *ngFor="let item of ratioLiveItems; let i = index">
              <td>
                <div class="flex">
                  <span class="item-title text-color h-1x bold">{{ item.brandName }}</span>
                </div>
              </td>
              <td class="flex">
                <div
                  [ngClass]="{ 'text-success': item.ratio >= 0, 'text-danger': item.ratio < 0 }"
                  class="item-except text-sm h-1x"
                >
                  <span *ngIf="item?.ratio > 0">+</span>
                  {{ item.ratio }}%
                </div>
              </td>
              <td>
                <span [ngClass]="{ 'text-success': item.ratio >= 0, 'text-danger': item.ratio < 0 }">
                  <strong style="font-weight: bolder; font-size: larger;">
                    <i *ngIf="item?.ratio >= 0" class="fas fa-chevron-up"></i>
                    <i *ngIf="item?.ratio < 0" class="fas fa-chevron-down"></i>
                  </strong>
                </span>
              </td>
            </tr>
            <tr *ngIf="!loaded">
              <td style="width: 20%;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="!loaded">
              <td style="width: 20%;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="!loaded">
              <td style="width: 20%;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="!loaded">
              <td style="width: 20%;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="!loaded">
              <td style="width: 20%;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 5%;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
  </div>
</div>
