import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { IAttribute } from '@app/home/models/attributes.model';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap, map } from 'rxjs/operators';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'attribute-list',
  templateUrl: './attributes.component.html',
  providers: [NgsRevealConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttributesListComponent implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild (NgModel) filterInput : NgModel;
  @Input() title: string;

  @Input() isCollapsed: boolean = false;
  @Input() disableFilter: boolean = false;
  @Input() set items(value: Observable<string[]>) {
    this.loaded = false;
    this._items = value;
  }
  @Output() selectedItemsEvent = new EventEmitter<Set<string>>();
  @Output() onDestroy = new EventEmitter<Set<string>>();

  filterItem: string;
  constructor(revealConfig: NgsRevealConfig, private spinner: NgxSpinnerService) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
    revealConfig.mobile = false;
  }
  ngOnDestroy(): void {
    this.onDestroy.emit(this.selectedItems);
  }
  selectedItems: Set<string> = new Set<string>();

  ngAfterViewInit(): void {
    // this.filterInput.valueChanges.subscribe(()=>this.performFilter(this.filterItem));
  }
  ngOnInit() {
    this._items.subscribe(() => (this.loaded = true));
  }

  onSelect(event: any, value: string) {
    if (!event.currentTarget.checked) {
      this.selectedItems.delete(value);
    } else this.selectedItems.add(value);
    this.selectedItemsEvent.emit(this.selectedItems);
  }

  performFilter(filterBy?: string): void {
    // if (filterBy) {
    //   this.filteredProducts = this.products.filter((product: IProduct) =>
    //     product.productName.toLocaleLowerCase().indexOf(filterBy.toLocaleLowerCase()) !== -1);
    // } else {
    //   this.filteredProducts = this.products;
    // }
  }
  loaded: boolean = false;
  _items: Observable<string[]>;
  itemsToWatch: string[];
  get items(): Observable<string[]> {
    return this._items?.pipe(
      finalize(() => {
        this.loaded = true;
      })
    );
  }
}
