import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { HomeRoutingModule } from './home-routing.module';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProductsComponent } from './products/products.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AttributesListComponent } from './products/attribute/attributes.component';
import { ProductsService } from './products/products.service';
import { PriceBoxComponent } from './products/priceBox/priceBox.component';
import { DataTablesModule } from 'angular-datatables';
import { ProductRowComponent } from './products/productRow/productRow.component';
import { FilterByPipe } from './products/attribute/filter.pipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HomeComponent } from './dashboard/home.component';
import { RatioHistoryComponent } from './dashboard/ratioHistory/ratioHistory.component';
import { DashboardService } from './dashboard/dashboard.service';
import { WatchListComponent } from './dashboard/watchList/watchList.component';
import { ComparisonChartComponent } from './dashboard/comparisonChart/comparisonChart.component';
import { RatioLiveComponent } from './dashboard/ratioLive/ratioLive.component';
import { ProfileComponent } from './profile/profile.component';
import { FaqComponent } from './faq/faq.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { PostComponent } from './networking/post/post.component';
import { PostReplyComponent } from './networking/post-reply/post-reply.component';
import { NetworkingComponent } from './networking/networking.component';
import { GlobalPostsComponent } from './networking/global-posts/global-posts.component';
import { PostDetailsComponent } from './networking/post-details/post-details.component';
import { MyPostsComponent } from './networking/my-posts/my-posts.component';
import { NewPostComponent } from './networking/new-post/new-post.component';
import { WatchListItemComponent } from './dashboard/watchList/watch-list-item/watch-list-item.component';
import { AvatarModule } from 'ngx-avatar';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';
import { PricingComponent } from './pricing/pricing.component';
import { TourComponent } from './dashboard/tour/tour.component';
import { MomentModule } from 'ngx-moment';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { PriceRowComponent } from './pricing/priceRow/priceRow.component';
import { MultiMatchPriceRowComponent } from './pricing/multiMatchPriceRow/multiMatchPriceRow.component';
import { DeviceCheckComponent } from './device-check/device-check.component';
import { ProviderGradeComponent } from './provider-grade/provider-grade.component';
import { ProviderGradeRowComponent } from './provider-grade/provider-grade-row/provider-grade-row.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};
@NgModule({
  imports: [
    SweetAlert2Module.forRoot(),
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    HomeRoutingModule,
    NgsRevealModule,
    NgbModule,
    NgxSpinnerModule,
    FormsModule,
    SlickCarouselModule,
    PerfectScrollbarModule,
    DataTablesModule,
    NgApexchartsModule,
    NgxSkeletonLoaderModule,
    AngularStickyThingsModule,
    ReactiveFormsModule,
    SnotifyModule,
    AvatarModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59,
      },
    }),
  ],
  declarations: [
    HomeComponent,
    ProductsComponent,
    AttributesListComponent,
    PriceBoxComponent,
    ProductRowComponent,
    PriceRowComponent,
    MultiMatchPriceRowComponent,
    FilterByPipe,
    WatchListComponent,
    WatchListItemComponent,
    ComparisonChartComponent,
    RatioLiveComponent,
    RatioHistoryComponent,
    ProfileComponent,
    FaqComponent,
    NetworkingComponent,
    PostComponent,
    PostReplyComponent,
    GlobalPostsComponent,
    PostDetailsComponent,
    MyPostsComponent,
    NewPostComponent,
    PricingComponent,
    TourComponent,
    DeviceCheckComponent,
    ProviderGradeComponent,
    ProviderGradeRowComponent,
  ],
  providers: [
    DashboardService,
    ProductsService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults },
    SnotifyService,
  ],
})
export class HomeModule {}
