import { Injectable } from '@angular/core';
import createAuth0Client, { GetTokenSilentlyOptions, GetUserOptions } from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenDetails } from './token.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client({
      domain: 'mobilityintel.us.auth0.com',
      client_id: 'qUVlv2ilFJvp0QHK772T62deijy5xc0f',
      redirect_uri: `${window.location.origin}`,
      audience: 'https://mobilityintel.com',
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => (this.loggedIn = res))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private router: Router, private cookieService: CookieService) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?: GetUserOptions): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => this.userProfileSubject$.next(user))
    );
  }

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#gettokensilently
  getTokenSilently$(options?: GetTokenSilentlyOptions): Observable<string> {
    return this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.getTokenSilently(options))));
  }
  getUserDetailsFromToken(): Observable<TokenDetails> {
    return this.getTokenSilently$().pipe(
      concatMap((token: string) => {
        var tokenParameters = this.parseJwt(token);
        var tokenDetails = new TokenDetails();
        tokenDetails.id = tokenParameters['https://mobilityintel.com/id'];
        tokenDetails.referenceId = tokenParameters['https://mobilityintel.com/referenceId'];
        tokenDetails.externalReferenceId = tokenParameters['https://mobilityintel.com/externalReferenceId'];
        tokenDetails.iss = tokenParameters['iss'];
        tokenDetails.sub = tokenParameters['sub'];
        tokenDetails.iat = tokenParameters['iat'];
        tokenDetails.exp = tokenParameters['exp'];
        tokenDetails.scope = tokenParameters['scope'];
        tokenDetails.permissions = tokenParameters['permissions'];
        tokenDetails.aud = tokenParameters['aud'];
        // If not authenticated, return stream that emits 'false'
        return of(tokenDetails);
      })
    );
  }
  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: `${window.location.origin}`,
        appState: { target: redirectPath },
      });
    });
  }

  // Create subject and public observable for handleAuthCallback status
  private handleAuthCallbackCompleteSubject$ = new BehaviorSubject<boolean>(false);
  handleAuthCallbackComplete$ = this.handleAuthCallbackCompleteSubject$.asObservable();

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes('code=') && params.includes('state=')) {
      let targetRoute: string; // Path to redirect to after login processed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap((cbRes) => {
          // Get and set target redirect route from callback results
          targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([this.getUser$(), this.isAuthenticated$, this.getTokenSilently$()]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn, token]) => {
        this.handleAuthCallbackCompleteSubject$.next(true);
        // this.getTokenSilently$().subscribe(s=>

        //   this.router.navigate(['/completeRegistration'])
        //   );
        // var tokenParameters = this.parseJwt(token);
        this.cookieService.set('pic', user.picture);
        localStorage.setItem('user', JSON.stringify(user));
        // if (!tokenParameters["https://mobilityintel.com/businessName"]) {
        //   this.router.navigate(['/completeRegistration']);
        // }

        // Redirect to target route after callback processing
        this.router.navigate([targetRoute]);
      });
    } else {
      this.handleAuthCallbackCompleteSubject$.next(true);
    }
  }
  parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: 'qUVlv2ilFJvp0QHK772T62deijy5xc0f',
        returnTo: window.location.origin + '/loggedout',
      });
    });
  }
}
