import { CompileShallowModuleMetadata } from '@angular/compiler';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';
import { Observable } from 'rxjs';
import { map, timeout } from 'rxjs/operators';
import { INamedAttribute } from '../models/attributes.model';
import { ICategory } from '../models/cartegory.model';
import { IEditionSearchForReturn } from '../models/pricing.model';
import { ProductsService } from '../products/products.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput: { nativeElement: { click: () => void; files: { [key: string]: File } } };
  file: File | null = null;
  status: number = 1;
  progressPage: boolean = true;
  errors: string[] = [];
  resultEditions: IEditionSearchForReturn[];
  constructor(private productResources: ProductsService, private snotifyService: SnotifyService) {}

  categories: Observable<INamedAttribute[]>;
  selectedCategory: string = '0';

  onClickFileInputButton(): void {
    this.fileInput.nativeElement.click();
  }

  onChangeFileInput(): void {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    this.file = files[0];
    this.status = 2;
    this.uploadFile();
  }
  ngOnInit(): void {
    this.categories = this.productResources.getCategories$();
  }
  onSelectCategory() {
    this.status = 2;
  }

  uploadFile() {
    setTimeout(() => {
      this.status = 3;
      this.onProgress();
    }, 50);
  }
  print() {
    // do other stuff...
    window.print();
  }
  onProgress() {
    let formData = new FormData();
    formData.append('file', this.file, this.file.name);
    this.productResources.postPricingRequest$(formData).subscribe(
      (g) => {
        this.status = 4;
        this.resultEditions = g;
        this.showResults();
      },
      (error) => {
        this.status = -1;
        this.errors.push(
          'The uploaded CSV file not valid, please make sure the provided CSV file is in the write format, you can download sample file'
        );
      }
    );
  }
  showResults() {
    setTimeout(() => {
      this.progressPage = false;
    }, 50);
  }
  retry() {
    this.selectedCategory = '0';
    this.status = 1;
    this.errors = [];
    this.file = null;
    this.progressPage = true;
  }
  onRemoveMatchResult(matchResult: IEditionSearchForReturn) {
    const index = this.resultEditions.indexOf(matchResult, 0);
    if (index > -1) {
      this.resultEditions.splice(index, 1);
    }
  }
}
