<div id="ajax-content" class="dark h-v d-flex flex align-items-center">
  <div class="mx-auto w-xl w-auto-xs animate fadeIn text-center">
    <div class="mb-3">
      <img [src]="userPic()" class="w-72 circle" />
      <div class="mt-3 font-bold">Goodbye, See you soon..</div>
    </div>

    <div class="mt-3">
      <a [routerLink]="['/home']" class="btn btn-rounded btn-success">Log in</a>
    </div>
  </div>
</div>
