import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, CredentialsService } from '@app/auth';
import { AuthService } from '@app/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '@app/user/userService.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuHidden = true;

  constructor(
    private router: Router,
    private authenticationService: AuthService,
    private userService: UserService,
    private credentialsService: CredentialsService,
    private cookieService: CookieService,
    private renderer: Renderer2
  ) {
    this.userService.getUser$().subscribe((g) => (this.username = g.fullName));
  }
  isDarkTheme = true;
  ngOnInit() {
    var themecolor = this.cookieService.get('themeColor');
    if (themecolor === 'light') {
      this.isDarkTheme = false;
    } else this.isDarkTheme = true;
  }
  toggleTheme() {
    var themecolor = this.cookieService.get('themeColor');
    if (themecolor) {
      this.renderer.addClass(document.body, 'bg-dark');
      this.cookieService.delete('themeColor');
    } else {
      this.cookieService.set('themeColor', 'light');
      this.renderer.removeClass(document.body, 'bg-dark');
    }

    this.isDarkTheme = !this.isDarkTheme;
  }

  toggleMenu() {
    this.menuHidden = !this.menuHidden;
  }
  logout() {
    this.authenticationService.logout();
    this.cookieService.deleteAll();
  }
  username: string;

  userPic() {
    return this.cookieService.get('pic');
  }
}
