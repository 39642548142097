<div class="pt-1" [ngsReveal]="{ reset: false }">
  <div class="mb-2 ml-2 row row-sm">
    <div class="col">
      <div class="d-flex">
        <div
          style="font-size: 16px; font-style: normal; font-weight: normal; line-height: 23px;"
          class="text-white text-md"
        >
          <div
            *ngIf="selectedItems.size > 0"
            style="height: 25px; width: 5px; background-color: #1ed300; display: block; float: left; margin-right: 5px;"
          ></div>
          <div
            *ngIf="selectedItems.size == 0"
            style="height: 25px; width: 5px; background-color: #a4aca2; display: block; float: left; margin-right: 5px;"
          ></div>
          <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <i class="fa fa-angle-down" *ngIf="!isCollapsed && loaded"></i>
            <i class="fa fa-angle-right" *ngIf="isCollapsed && loaded"></i>
            <i class="fa fa-angle-right" *ngIf="!loaded"></i>
            {{ title }}</a
          >
        </div>
        <div *ngIf="!loaded" class="ml-2 loading"></div>
      </div>
    </div>
  </div>
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div *ngIf="loaded && !disableFilter" style="margin-left: 30px;" class="row mb-2">
      <div class="col-10">
        <div class="input-group flex bg-light rounded">
          <input
            #itemm
            [(ngModel)]="filterItem"
            type="text"
            class="form-control form-control-sm no-bg no-border no-shadow search"
            placeholder="Filter"
          />
          <span class="input-group-append">
            <span class="btn no-bg no-shadow"><i class="fas fa-search small text-fade"></i></span>
          </span>
        </div>
      </div>
    </div>
    <div style="margin-left: 30px;" class="row row-sm">
      <div class="col-11">
        <perfect-scrollbar style="max-height: 200px;">
          <div class="checkbox-list-group">
            <div
              *ngFor="let item of items | async | filterByPipe: filterItem:selectedItems; let i = index"
              class="mb-2"
            >
              <label class="ui-check ui-check-md">
                <input type="checkbox" class="appCheckbox" (change)="onSelect($event, item)" />
                <i class="white"></i>
                {{ item }}
              </label>
            </div>
          </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</div>
