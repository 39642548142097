import { Injectable, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class CookiesStorageService {
  private hasStorage: boolean;

  constructor(private cookieService: CookieService) {
    this.hasStorage = typeof Storage !== 'undefined';
  }

  public read(key: string): any {
    if (this.hasStorage) {
      let val = this.cookieService.get(key);
      //   let val = localStorage.getItem(key + '_' + this.oidcConfig.openIdConfiguration.client_id);
      if (val && val != '') {
        // return val;
        //   console.log('userData: JSON.parse(val):   ', JSON.parse(val));
        return JSON.parse(val);
      }
    }

    return;
  }

  public write(key: string, value: any): void {
    if (this.hasStorage && value && value != null && value != '') {
      value = value === undefined ? null : value;
      // if (key == 'userData')
      //   console.log('userData: JSON.stringify(value):   ', JSON.stringify(value));
      this.cookieService.set(
        //   localStorage.setItem(
        key,
        // value
        JSON.stringify(value)
        //,        10,'/myapp/',null
      );
    }
    //  this.cookieService.set(key, JSON.stringify(value));
  }
}
