<div class="modal-header">
  <h4 class="modal-title">
    Quickstart Guide
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ngb-carousel>
    <ng-template ngbSlide>
      <div style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" class="card">
        <!-- <div class="media media-4x3 gd-info"> -->

        <!-- <a class="media-content" style="background-image: url();">
                    <strong class="text-fade">Card media</strong>
                  </a> -->
        <!-- </div> -->
        <img style="width: 100%;" src="assets/tour/1.jpg" />
        <div class="card-body">
          <h5 class="card-title">Watch List</h5>
          <p class="card-text">
            Click "+Add To Your Watchlist"
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" class="card">
        <img style="width: 100%;" src="assets/tour/2.jpg" />
        <div class="card-body">
          <h5 class="card-title">Products Filtering</h5>
          <p class="card-text">
            Filter products or services to identify the products or services based on the criteria you need, then click
            "Search"
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" class="card">
        <img style="width: 100%;" src="assets/tour/3.jpg" />
        <div class="card-body">
          <h5 class="card-title">Add to Watchlist</h5>
          <p class="card-text">
            Once you have selected the products or services you would like, click "Add to Watchlist" then Go to your
            Dashboard by clicking "Home".
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" class="card">
        <img style="width: 100%;" src="assets/tour/4.jpg" />
        <div class="card-body">
          <h5 class="card-title">Monitoring Items</h5>
          <p class="card-text">
            Here, you can view all of the items you have added to your Watchlist using the various tools available. The
            "$" sign will give you a small chart with the grade and price associated with it. If you click the "History
            Chart Icon", you will have access to the historical trending values of the device you have selected.
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" class="card">
        <img style="width: 100%;" src="assets/tour/5.jpg" />
        <div class="card-body">
          <h5 class="card-title">History Chart</h5>
          <p class="card-text">
            View and compare the historical trending values of the device you have selected.
          </p>
        </div>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div style="visibility: visible; transform: none; opacity: 1; transition: none 0s ease 0s;" class="card">
        <img style="width: 100%;" src="assets/tour/6.jpg" />
        <div class="card-body">
          <h5 class="card-title">Market Prices Ratio</h5>
          <p class="card-text">
            Enjoy the platform and its wide variety of tools, tips, and services offered!
          </p>
        </div>
      </div>
    </ng-template>
  </ngb-carousel>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-raised btn-wave mb-2 w-xs purple" (click)="activeModal.close('Close click')">
    Close
  </button>
</div>
