<div>
  <!-- sidenav top -->
  <div class="navbar">
    <!-- brand -->
    <a [routerLink]="['/home']" class="navbar-brand">
      <img class="logo" />
      <span class="hidden-folded d-inline l-s-n-1x">MobilityIntel</span>
    </a>
    <!-- / brand -->
  </div>
  <!-- Flex nav content -->
  <div class="flex scrollable hover">
    <div class="nav-active-text-primary" data-nav>
      <ul class="nav bg">
        <li class="nav-header hidden-folded">
          <span class="text-muted">Main</span>
        </li>
        <li [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <a [routerLink]="['/home']">
            <span class="nav-icon text-primary"><i class="fas fa-home"></i></span>
            <span class="nav-text">Home</span>
          </a>
        </li>
        <li class="nav-header hidden-folded">
          <span class="text-muted"></span>
        </li>
        <li [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <a [routerLink]="['/products']">
            <span class="nav-icon text-info"><i class="fas fa-search-dollar"></i></span>
            <span class="nav-text">Discover Products Prices</span>
          </a>
        </li>
        <li [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <a [routerLink]="['/networking']" class="nav-link disabled">
            <span class="nav-icon text-muted"><i class="fas fa-comments-dollar"></i></span>
            <span class="nav-text text-muted">Networking</span>
            <span class="nav-icon text-info"><i class="fas fa-lock"></i></span>
          </a>
        </li>
        <li [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <a [routerLink]="['/pricing']" class="nav-link disabled">
            <span class="nav-icon text-muted"><i class="fas fa-tags"></i></span>
            <span class="nav-text text-muted">Pricing</span>
            <span class="nav-icon text-info"><i class="fas fa-lock"></i></span>
          </a>
        </li>
        <li [routerLinkActiveOptions]="{ exact: true }" [routerLinkActive]="['active']">
          <a [routerLink]="['/deviceCheck']" class="nav-link disabled">
            <span class="nav-icon text-muted"><i class="fas fa-check"></i></span>
            <span class="nav-text text-muted">Check Device</span>
            <span class="nav-icon text-info"><i class="fas fa-lock"></i></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- sidenav bottom -->
  <!-- <div class="no-shrink">
    <div class="p-3 d-flex align-items-center">
      <div class="text-sm hidden-folded text-muted">
        Trial: 35%
      </div>
      <div class="progress mx-2 flex" style="height: 4px;">
        <div class="progress-bar gd-success" style="width: 35%;"></div>
      </div>
    </div>
  </div> -->
  <!-- <div class="no-shrink ml-2" style="width: 230px;">
    <ngb-alert class="alert alert-warning mt-4" [type]="'warning'">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-alert-circle"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="12" y1="8" x2="12" y2="12"></line>
        <line x1="12" y1="16" x2="12" y2="16"></line>
      </svg>
      <span>This is a demo version, full version will be here very soon </span>
    </ngb-alert>
  </div> -->
</div>
