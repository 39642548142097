export class TokenDetails {
  id: string;
  referenceId: string;
  externalReferenceId: string;
  iss: string;
  sub: string;
  aud: string[];
  iat: string;
  exp: string;
  scope: string;
  permissions: string[];
}
