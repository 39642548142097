<div style="min-height: 550px; overflow: hidden; max-height: 600px;" [ngsReveal]="{ reset: false }" class="card pb-3">
  <div *ngIf="!UiReady" class="modal2 link_color belowButton">
    <div style="color: white;">
      <span style="font-size: 30px;"><i class="fas fa-chart-line"></i></span><br />There are no products selected to
      process
    </div>
  </div>
  <ngx-spinner [name]="'loading'" bdColor="rgba(2, 4, 3, 0.21)" type="ball-grid-pulse" size="default">
    <p style="margin-top: 45px; color: white;">Loading...</p>
  </ngx-spinner>
  <div class="p-3-4">
    <div class="d-flex">
      <div>
        <div class="text-white text-md text-highlight">History Chart</div>
      </div>
      <span class="flex"></span>
      <!-- <div *ngIf="watchListitems?.length > 0">
          <div
            *ngIf="!showUpdateWatchList"
            class="btn-group sideDropdown ml-2"
            ngbDropdown
            container="body"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <a ngbDropdownToggle><i class="fas fa-ellipsis-h"></i></a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button *ngIf="!uiExtend" (click)="extendWatchList()" ngbDropdownItem>Extend</button>
              <button *ngIf="uiExtend" (click)="reduceWatchList()" ngbDropdownItem>Reduce</button>
              <button (click)="editWatchList()" ngbDropdownItem>Edit</button>
            </div>
          </div>
          <div *ngIf="showUpdateWatchList">
            <a (click)="saveWatchListUpdate()" class="btn btn-sm btn-white">Save</a>
          </div>
        </div> -->
    </div>
  </div>
  <div *ngIf="editionHourly" class="row row-sm ml-2 mt-4 text-muted">
    <div class="col">
      <div class="row row-sm">
        <div class="col-12">
          <div class="chips">
            <div
              *ngFor="let item of itemsToGetChart; let i = index"
              [style.background-color]="item.color"
              class="chip text-white"
            >
              {{ item.editionName }}
              <a (click)="removeFromChart(item)"> <i class="fas fa-times small"></i> </a>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="UiReady" class="row row-sm">
        <div class="col-12">
          <div ngbRadioGroup name="radioBasic" (change)="selectDuration()" [(ngModel)]="selectedDuration">
            <div class="col-3 btn-group btn-group-toggle">
              <label
                *ngFor="let item of duration; let i = index"
                ngbButtonLabel
                class="btn w-sm mb-1 btn-outline-success bold text-white"
              >
                <input ngbButton type="radio" [value]="item.value" /> {{ item.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="UiReady" class="row row-sm">
        <div class="col-12">
          <div class="flex text-center">
            <div>
              <apx-chart
                #chart
                [noData]="noData"
                [colors]="rColors"
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [dataLabels]="chartOptions.dataLabels"
                [markers]="chartOptions.markers"
                [title]="chartOptions.title"
                [fill]="chartOptions.fill"
                [annotations]="chartOptions.annotations"
                [yaxis]="chartOptions.yaxis"
                [xaxis]="chartOptions.xaxis"
                [tooltip]="chartOptions.tooltip"
                [autoUpdateSeries]="true"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
