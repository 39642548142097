import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { ProductsService } from './products.service';
import { map, tap, finalize } from 'rxjs/operators';
import {
  INamedAttribute,
  IAttribute,
  ICatigoryDependencies,
  IAttributeValue,
  IDependencyItem,
} from '../models/attributes.model';
import { from, Subject, of } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { IProduct } from '../models/search.model';
import {
  ISearchCriteria,
  IAttributeCriteria,
  IOrderByCriteria,
  orderByCriteriaElements,
} from '../models/searchCriteria.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { IPriceRange } from '../models/priceRange.model';

@Component({
  selector: 'app-shell',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [NgsRevealConfig],
})
export class ProductsComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  constructor(
    revealConfig: NgsRevealConfig,
    private productsService: ProductsService,
    private spinner: NgxSpinnerService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  /* UI Variables */
  isCollapsed = false;
  dependanciesUi: IDependencyItem[] = [];
  selectedDependancies: Set<string> = new Set<string>();
  loadingDone: boolean = false;
  searchResults: IProduct[] = [];
  pageLength: number[] = [10, 20, 50, 100];
  sortByTypes: string[] = orderByCriteriaElements.map((g) => g.name);
  selectedPageLength: number = 10;
  selectedSortBy: string;
  toggleFilter = true;
  dtOptions: DataTables.Settings;
  searchCriteria: ISearchCriteria = new ISearchCriteria();
  dependancies: ICatigoryDependencies[] = [];
  filterBoxes: IAttributeValue[] = [];

  ngOnInit() {
    this.searchCriteria.attributeCriteria = [];
    this.searchCriteria.take = this.selectedPageLength;
    this.searchCriteria.orderByCriteria = new IOrderByCriteria();
    this.selectedSortBy = this.sortByTypes[0];
    this.initiateDatatable();
  }

  brands = this.productsService.getBrands$().pipe(
    map((body: INamedAttribute[]) => {
      return body.map((t) => t.name);
    })
  );

  category = this.productsService.getCategories$().pipe(
    tap((body) => {
      this.dependancies = body.map((r) => {
        let value: ICatigoryDependencies = {
          name: r.name,
          values: [],
        };
        r.attributes.map((at) => {
          value.values.push(at.name);
          const found = this.filterBoxes.some((el) => el.name === at.name);
          if (!found) this.filterBoxes.push(at);
        });
        return value;
      });
    }),
    map((body: INamedAttribute[]) => {
      return body.map((t) => t.name);
    }),
    finalize(() => {})
  );

  changePrice(price: IPriceRange) {
    this.searchCriteria.minPrice = price.min;
    this.searchCriteria.maxPrice = price.max;
  }
  changePageLength() {
    this.searchCriteria.take = this.selectedPageLength;
    this.dtOptions.pageLength = this.selectedPageLength;
    this.search();
  }
  changeSortBy() {
    this.searchCriteria.orderByCriteria = new IOrderByCriteria();
    let sortbyElement = orderByCriteriaElements.find((f) => f.name == this.selectedSortBy);
    this.searchCriteria.orderByCriteria.keyword = sortbyElement.value;
    this.searchCriteria.orderByCriteria.sortType = sortbyElement.type;
    this.search();
  }
  unSelectedItems(type: string, values: Set<string>) {
    var selectedAttrVal = this.searchCriteria?.attributeCriteria?.find((d) => d.attributeKey == type)?.attributeValues;
    if (selectedAttrVal)
      this.searchCriteria.attributeCriteria.find(
        (d) => d.attributeKey == type
      ).attributeValues = selectedAttrVal.filter((h) => !values.has(h));
  }
  selectedItems(type: string, values: Set<string>) {
    if (type === 'Brands') {
      this.searchCriteria.brandNames = [...values];
      return;
    }
    if (type === 'Category' && values.size != 0) {
      this.searchCriteria.categoryNames = [...values];
      this.selectedDependancies.clear();
      values.forEach((element) => {
        var dep = this.dependancies.find((a) => a.name == element).values;
        dep.forEach((ielement) => {
          if (!this.selectedDependancies.has(ielement)) {
            this.selectedDependancies.add(ielement);
          }
        });
      });
      this.initiateDependenciesFilters();
    } else if (type === 'Category' && values.size == 0) {
      this.searchCriteria.categoryNames = [...values];
      this.dependanciesUi = [];

      // this.searchCriteria.attributeCriteria.find((g) => g.attributeKey == type).attributeValues = [];
    } else {
      var attr = this.searchCriteria.attributeCriteria.find((f) => f.attributeKey == type);
      if (attr) {
        attr.attributeValues = [...values];
      } else {
        let attrToAdd: IAttributeCriteria = {
          attributeKey: type,
          attributeValues: [...values],
        };
        this.searchCriteria.attributeCriteria.push(attrToAdd);
      }
    }
  }

  initiateDependenciesFilters() {
    this.dependanciesUi = this.dependanciesUi.filter((t) => this.selectedDependancies.has(t.name));
    this.selectedDependancies.forEach((element) => {
      if (this.dependanciesUi.find((d) => d.name == element)) return;
      var er = this.filterBoxes
        .filter((t) => t.name === element)
        .map((j) => {
          return j.values;
        });
      let itemToAdd: IDependencyItem = {
        name: element,
        items: from(er),
      };
      this.dependanciesUi.push(itemToAdd);
    });
  }

  initiateDatatable() {
    const that = this;
    this.dtOptions = {
      ordering: false,
      responsive: true,

      pageLength: that.selectedPageLength,
      search: false,
      searching: false,
      preDrawCallback: (settings) => {
        settings._iDisplayLength = that.selectedPageLength;
      },
      ajax: (dataTablesParameters: any, callback) => {
        that.searchCriteria.skip = Number.parseInt(dataTablesParameters.start);
        this.searchCriteria.take = dataTablesParameters.length;
        this.loadingDone = false;
        this.spinner.show('searchArea');
        this.productsService.search$(this.searchCriteria).subscribe((resp) => {
          that.searchResults = resp.result;
          this.spinner.hide('searchArea');
          this.loadingDone = true;
          callback({
            recordsTotal: resp.totalCount,
            recordsFiltered: resp.totalCount,
            data: [],
          });
        });
      },
      serverSide: true,
      processing: false,
    };
  }

  addIfNotExist(item: string, array: string[]) {
    const found = array.some((el) => el === item);
    if (!found) array.push(item);
    return array;
  }

  search() {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      //   dtInstance.columns().every(function () {
      //     const that = this;
      //     $('input', this.header()).on('keyup change', function () {
      //       if (that.search() !== this['value']) {
      //         that
      //           .search(this['value'])
      //           .draw();
      //       }
      //     });
      //   });
      // });
      dtInstance.draw();
    });
  }
}
