import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { error } from 'jquery';
import { IProviderGradeForReturn } from '../models/providerGrade.model';
import { ProviderGradeService } from './provider-grade.service';
import { ProviderGradeRowComponent } from './provider-grade-row/provider-grade-row.component'; // Import the component

@Component({
  selector: 'app-provider-grade',
  templateUrl: './provider-grade.component.html',
  styleUrls: ['./provider-grade.component.scss'],
})
export class ProviderGradeComponent implements OnInit {
  rows: IProviderGradeForReturn[];
  isProcessing: boolean = false;
  isExceedLimit: boolean = false;

  constructor(private route: ActivatedRoute, private providerGradeService: ProviderGradeService) {}

  ngOnInit(): void {
    const id: string = this.route.snapshot.params['id'];
    this.onLoadRows(id);
  }

  onLoadRows(editionRefId: string) {
    this.isProcessing = true;
    this.isExceedLimit = false;
    this.providerGradeService.getProviderGradeById$(editionRefId).subscribe(
      (f) => {
        console.log(f);

        this.rows = f;
      },
      (x) => {
        this.rows = null;
        if (x.error.errors[0].code === 2224) {
          this.isExceedLimit = true;
          this.isProcessing = false;
        }
      },
      () => {
        this.isProcessing = false;
      }
    );
  }
}
