import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/user/userService.service';
import { NetworkingService } from '../networking.service';
import * as jquery from 'jquery';
import 'select2';
import { ITopic } from '../models/post.model';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.scss'],
})
export class NewPostComponent implements OnInit {
  replyForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private networkingService: NetworkingService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private auth: AuthService
  ) {}
  ngOnInit(): void {
    this.auth.getTokenSilently$().subscribe((token) => {
      jquery('#id_label_multiple').select2({
        placeholder: 'What is your post about?',
        ajax: {
          headers: { Authorization: `Bearer ${token}` },
          url: environment.serverUrl + '/tags',
          processResults: function (data) {
            return {
              results: $.map(data, function (obj) {
                return { id: obj, text: obj };
              }),
            };
          },
          data: function (params) {
            var query = {
              tagName: params.term,
            };
            return query;
          },
        },
      });
    });

    this.replyForm = this.formBuilder.group({
      message: ['', Validators.required],
    });
  }
  clearSelectedTagsItems() {
    $('#id_label_multiple').val(null);
    $('#id_label_multiple').html('');
  }
  getSelectedTagsItems() {
    return $('.js-example-basic-multiple').find(':selected');
  }
  onSubmit() {
    this.submitted = true;
    var selectedItems = this.getSelectedTagsItems();
    var tagsToAdd = selectedItems.map(function (index, ele) {
      return ele.innerText;
    });
    // stop here if form is invalid
    if (this.replyForm.invalid) {
      console.log(this.replyForm);
      return;
    }
    var textToSend = this.replyForm.get('message').value;

    // this.replyForm.controls['message'].setValue('');
    // this.clearSelectedTagsItems();
    this.networkingService
      .newPost$({
        description: textToSend,
        topics: tagsToAdd.toArray().map((g) => {
          let topic: ITopic = {
            name: g,
          };
          return topic;
        }),
      })
      .subscribe(
        (g) => {
          this.router.navigate(['networking/posts/global']);
        },
        (error) => {}
      );
  }
  submitted: boolean = false;
  get replyFormControles() {
    return this.replyForm.controls;
  }
}
