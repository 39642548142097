<div>
  <div class="page-hero page-container" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title">
        <h2 class="text-md text-highlight">Profile</h2>
        <small class="text-muted">Your personal information</small>
      </div>
    </div>
  </div>
  <div class="page-content page-container" id="page-content">
    <div class="padding sr">
      <div [ngsReveal]="{ reset: false }" class="card">
        <div
          class="card-header bg-dark bg-img p-0 no-border"
          data-stellar-background-ratio="0.1"
          style="background-image: url(../assets/img/b3.jpg);"
          data-plugin="stellar"
        >
          <div class="bg-dark-overlay r-2x no-r-b">
            <div class="d-md-flex">
              <div class="p-4">
                <div class="d-flex">
                  <a>
                    <span class="avatar w-64">
                      <img [src]="user.imageUrl" alt="." />
                      <i class="on"></i>
                    </span>
                  </a>
                  <div class="mx-3">
                    <h5 class="mt-2">{{ user.fullName }}</h5>
                    <div class="text-fade text-sm">
                      <span class="m-r">{{ user.business?.name }}</span>
                      <!-- <small>
                                                <i class="fa fa-map-marker mr-2"></i>London, UK</small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3">
          <div class="d-flex">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link" data-toggle="tab" data-target="#tab_4">Profile</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 col-lg-12">
          <div class="tab-content">
            <div class="tab-pane fade show active" id="tab_1">
              <div [ngsReveal]="{ reset: false }" class="card">
                <div class="px-4 py-4">
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Cell Phone</small>
                      <div class="my-2">{{ user.phoneNumber }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Email</small>
                      <div class="my-2">{{ user.email }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Business Name</small>
                      <div class="my-2">{{ user.business?.name }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Website</small>
                      <div class="my-2">{{ user.business?.website }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Established Year</small>
                      <div class="my-2">{{ user.business?.yearEstablish }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">EIN / VAT</small>
                      <div class="my-2">------</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-6">
                      <small class="text-muted">Business Field </small>
                      <div class="my-2">{{ user.business?.businessField }}</div>
                    </div>
                    <div class="col-6">
                      <small class="text-muted">Business Structure </small>
                      <div class="my-2">{{ user.business?.ownershipType }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
