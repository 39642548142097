export class ISearchResponse {
  result: IProduct[];
  totalCount: number;
}
export class IProduct {
  referenceId: string;
  name: string;
  close: number;
  highest: number;
  lowest: number;
  ratio: number;
  currentAverage: number;
  isExistInWatchList: boolean;
  brandName: string;
  categoryName: string;
  productName: string;
  attributes: IReturnedAttributeCriteria[];
  isActive: boolean;
}

export class IReturnedAttributeCriteria {
  attributeName: string;
  attributeValue: string;
}
