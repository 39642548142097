<div>
  <div class="page-hero" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title only-print">
        <h2 class="text-md text-highlight">Mobility Intel</h2>
        <small class="text-muted">This price evaluation report of seclected products names. </small>
      </div>
      <div class="page-title no-print">
        <h2 class="text-md text-highlight">Pricing</h2>
        <small class="text-muted">Get full price evaluation report of seclected products models</small>
      </div>
      <div class="flex"></div>
      <div *ngIf="!progressPage">
        <a (click)="retry()" class="btn btn-md text-muted no-print">
          <i style="color: #f54394;" class="fas fa-redo"></i>
          <span class="d-none d-sm-inline mx-1">New File</span>
        </a>
      </div>
      <div *ngIf="!progressPage">
        <a (click)="print()" class="btn btn-md text-muted no-print">
          <i style="color: #f54394;" class="fas fa-print"></i>
          <span class="d-none d-sm-inline mx-1">Print</span>
        </a>
      </div>
    </div>
  </div>
  <div class="page-content" id="page-content">
    <div class="padding">
      <div *ngIf="progressPage" class="row no-print">
        <div class="col-3"></div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="list list-row block">
            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div>
                <a class="nav-link text-center active" data-toggle="tab">
                  <span
                    [ngClass]="{ 'active-bg-success': status >= 1 }"
                    class="w-40 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success"
                    >1</span
                  >
                </a>
              </div>
              <div class="flex">
                <a class="item-author text-color">Select a category:</a>
                <div class="col-sm-4" style="padding-left: 0%; padding-top: 5px;">
                  <select
                    [(ngModel)]="selectedCategory"
                    name="Category"
                    (change)="onSelectCategory()"
                    class="form-control form-control-sm"
                    data-minimum-results-for-search="Infinity"
                  >
                    <option value="0" disabled selected>Select Category</option>
                    <option class="selectOptions" *ngFor="let item of categories | async" [ngValue]="item.name">
                      {{ item.name }}</option
                    >
                  </select>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div>
                <a class="nav-link text-center active" data-toggle="tab">
                  <span
                    [ngClass]="{ 'active-bg-success': status >= 2 }"
                    class="w-40 d-inline-flex align-items-center justify-content-center circle bg-light active-bg-success"
                    >2</span
                  >
                </a>
              </div>
              <div class="flex" [ngClass]="{ 'text-muted': status < 2 }">
                <a class="item-author text-color">Make sure it's valid file structure</a>
                <div *ngIf="status >= 2" class="item-except text-muted text-sm h-1x">
                  Please
                  <a
                    style="color: #f54394;"
                    href="assets/PricingSamples/PricingSampleFile_{{ selectedCategory }}.csv"
                    download="PricingSampleFile_{{ selectedCategory }}.csv"
                    >download the sample file: {{ selectedCategory }}</a
                  >
                  from here.
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="list-item" data-id="11">
              <div>
                <a class="nav-link text-center active" data-toggle="tab">
                  <span
                    [ngClass]="{ 'active-bg-success': file }"
                    class="w-40 d-inline-flex align-items-center justify-content-center circle bg-light"
                    >3</span
                  >
                </a>
              </div>
              <div class="flex" [ngClass]="{ 'text-muted': status < 2 }">
                <a class="item-author text-color">Upload your product lists</a>
                <div *ngIf="!file; else fileName" class="item-except text-muted text-sm h-2x">
                  <input type="file" style="display: none;" #fileInput accept=".csv" (change)="onChangeFileInput()" />
                  <button
                    *ngIf="status >= 2"
                    (click)="onClickFileInputButton()"
                    class="btn btn-raised btn-wave w-sm mb-1 mt-1 purple"
                  >
                    Select File
                  </button>
                </div>
                <ng-template #fileName>
                  <div class="item-except text-muted text-sm h-2x">You have selected the file {{ file?.name }}</div>
                </ng-template>
                <div *ngIf="status == 3" class="row row-sm">
                  <div class="col-1 text-center">
                    <div style="display: inline-block;" class="loading"></div>
                  </div>
                  <div class="col-11 item-except text-muted text-sm h-2x">Uploading file, please wait</div>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="list-item" data-id="10">
              <div>
                <a class="nav-link text-center active" data-toggle="tab">
                  <span
                    [ngClass]="{ 'active-bg-success': status >= 4 }"
                    class="w-40 d-inline-flex align-items-center justify-content-center circle bg-light"
                    >4</span
                  >
                </a>
              </div>
              <div class="flex">
                <a [ngClass]="{ 'text-muted': status < 3 }" class="item-author text-color"
                  >Approving and analyzing products</a
                >
                <div *ngIf="errors && status == -1">
                  <div *ngFor="let error of errors">
                    <span class="badge badge-circle text-danger"></span>
                    <a class="item-title text-warning text-sm">{{ error }}</a>
                  </div>
                  <div>
                    <button (click)="retry()" class="btn btn-raised btn-wave w-sm mb-1 mt-1 purple">Retry again</button>
                  </div>
                </div>
                <div *ngIf="status == 4" class="row row-sm">
                  <div class="col-1 text-center">
                    <div style="display: inline-block;" class="loading"></div>
                  </div>
                  <div class="col-11 item-except text-muted text-sm h-2x">In progress please wait</div>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="list-item" data-id="20">
              <div>
                <a class="nav-link text-center active" data-toggle="tab">
                  <span
                    [ngClass]="{ 'active-bg-success': status >= 5 }"
                    class="w-40 d-inline-flex align-items-center justify-content-center circle bg-light"
                    >5</span
                  >
                </a>
              </div>
              <div class="flex">
                <a [ngClass]="{ 'text-muted': status < 4 }" class="item-author text-color">Review the results</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3"></div>
      </div>
      <div *ngIf="status == 4 && !progressPage" class="row row-sm">
        <div class="col-12">
          <div class="table-responsive">
            <table class="table table-theme table-row v-middle">
              <thead>
                <tr>
                  <th class="text-muted">Provided Product</th>
                  <th class="text-muted"><span class="d-none d-sm-block">Grade</span></th>
                  <th class="text-muted">Matched Product(s)</th>
                  <th class="text-muted"><span class="d-none d-sm-block">Specifications</span></th>
                  <th class="text-muted"><span class="d-none d-sm-block">Price</span></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let matchResult of resultEditions; let i = index"
                  [ngClass]="{ priceNotActive: matchResult.hasOwnProperty('isActive') && !matchResult.isActive }"
                  price-row
                  (onRemoveMatchResult)="onRemoveMatchResult($event)"
                  [matchResult]="matchResult"
                ></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
