import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { IAttribute } from '@app/home/models/attributes.model';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap, map } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { IPriceRange } from '@app/home/models/priceRange.model';
import { IProduct } from '@app/home/models/search.model';
import { ProductsService } from '@app/home/products/products.service';
import { IEditionSearchForReturn } from '@app/home/models/pricing.model';

@Component({
  selector: '[multiMatchPriceRow-row]',
  templateUrl: './multiMatchPriceRow.component.html',
  providers: [NgsRevealConfig],
})
export class MultiMatchPriceRowComponent implements OnInit {
  @Input() matchEdition: IProduct;
  @Input() index: number;
  @Output() onCorrectMatchSelected = new EventEmitter<number>();

  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private productsService: ProductsService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  loader = false;

  ngOnInit() {}

  selectCorrentMatch() {
    this.onCorrectMatchSelected.emit(this.index);
  }
}
