import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { finalize, tap, map } from 'rxjs/operators';

import { NgsRevealConfig } from 'ngx-scrollreveal';
import { AuthService } from '@app/auth/auth.service';
import { DashboardService } from './dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '@app/user/userService.service';
import { CookieService } from 'ngx-cookie-service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TourComponent } from './tour/tour.component';
interface Alert {
  type: string;
  message: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgsRevealConfig],
})
export class HomeComponent implements OnInit {
  isLoading = false;
  /* UI variables */
  isWatchListExtend = false;
  watchListExtend(state: boolean) {
    this.isWatchListExtend = state;
  }
  staticAlertClosed = false;

  /* process variables */

  constructor(
    private revealConfig: NgsRevealConfig,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    public auth: AuthService,
    private userService: UserService,
    private cookieService: CookieService,
    private modalService: NgbModal
  ) {
    userService.getUser$().subscribe((f) => {
      this.username = f.fullName;
    });
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  username: string;
  ngOnInit() {
    var staticAlertClosed = this.cookieService.get('staticAlert');
    if (staticAlertClosed == 'true') {
      this.staticAlertClosed = true;
    } else {
      this.staticAlertClosed = false;
    }
  }
  /* Alert Box */
  close() {
    console.log('hhhhhhhhh');
    this.staticAlertClosed = true;
    this.cookieService.set('staticAlert', 'true');
  }

  openTour() {
    const modalRef = this.modalService.open(TourComponent, {
      animation: true,
    });
    modalRef.componentInstance.name = 'World';
  }
}
