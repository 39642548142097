<div class="page-hero page-container" id="page-hero">
  <div class="padding d-flex" style="padding-top: 0px;">
    <div class="page-title">
      <h2 class="text-md text-highlight">Products List</h2>
      <small class="text-muted">Discover and watch product prices </small>
    </div>
    <div class="flex"></div>
  </div>
</div>
<div class="page-content page-container pb-5" id="page-content">
  <div class="row row-sm sr">
    <div class="col-md-12 col-lg-3">
      <div class="row row-sm">
        <div class="col-12 d-block d-md-none">
          <button
            (click)="toggleFilter = !toggleFilter"
            style="width: 100%;"
            class="btn btn-raised btn-wave mb-2 w-xs purple"
          >
            Filter
          </button>
        </div>
        <div [ngClass]="{ 'd-none d-sm-block ': toggleFilter, forceShowCards: !toggleFilter }" class="col-12">
          <div class="row row-sm">
            <div class="col-12">
              <div class="card ml-3 pt-3 pb-3">
                <attribute-list
                  (selectedItemsEvent)="selectedItems('Brands', $event)"
                  [disableFilter]="true"
                  [items]="brands"
                  [title]="'Brands'"
                >
                </attribute-list>
                <attribute-list
                  [disableFilter]="true"
                  (selectedItemsEvent)="selectedItems('Category', $event)"
                  [items]="category"
                  [title]="'Category'"
                ></attribute-list>
                <div *ngFor="let s of dependanciesUi; let i = index; first as isFirst">
                  <attribute-list
                    (selectedItemsEvent)="selectedItems(s.name, $event)"
                    (onDestroy)="unSelectedItems(s.name, $event)"
                    [items]="s.items"
                    [title]="s.name"
                    [isCollapsed]="!isFirst"
                  ></attribute-list>
                </div>
                <price-box
                  (searchEvent)="search()"
                  (selectedPrice)="changePrice($event)"
                  [title]="'Price Range'"
                  [isCollapsed]="false"
                ></price-box>
              </div>
            </div>
            <div class="col-12">
              <button (click)="search()" style="width: 100%;" class="btn btn-raised btn-wave mb-2 w-xs purple">
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-9">
      <div class="row row-sm">
        <div class="col-12">
          <div class="row row-sm">
            <ngx-spinner
              name="searchArea"
              [fullScreen]="false"
              bdColor="rgba(2, 4, 3, 0.21)"
              type="ball-grid-pulse"
              size="default"
            >
              <!-- <p style="  color: white;">Loading...</p> -->
            </ngx-spinner>
            <div class="col-12">
              <div class="">
                <div class="row row-sm">
                  <div class="col-lg-1 col-md-3">
                    <select
                      (change)="changePageLength()"
                      [(ngModel)]="selectedPageLength"
                      class="form-control form-control-sm"
                      data-minimum-results-for-search="Infinity"
                      aria-hidden="true"
                    >
                      <option *ngFor="let c of pageLength" [ngValue]="c">{{ c }}</option>
                    </select>
                  </div>
                  <div class="col-lg-3 col-md-3">
                    <select
                      (change)="changeSortBy()"
                      [(ngModel)]="selectedSortBy"
                      class="form-control form-control-sm"
                      data-minimum-results-for-search="Infinity"
                      aria-hidden="true"
                    >
                      <option *ngFor="let c of sortByTypes" [ngValue]="c">{{ c }}</option>
                    </select>
                  </div>
                  <div class="col-lg-6 col-md-8">
                    <div class="input-group flex bg-light rounded">
                      <input
                        type="text"
                        [(ngModel)]="searchCriteria.editionName"
                        class="form-control form-control-sm no-bg no-border no-shadow search"
                        placeholder="Search"
                      />
                      <button
                        (click)="search()"
                        class="btn btn-raised btn-wave btn-icon btn-rounded teal teal text-white"
                      >
                        <i class="fas fa-search small text-fade"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <table
                    datatable
                    #datatable
                    [dtOptions]="dtOptions"
                    id="datatable"
                    class="table table-theme table-row v-middle"
                    data-plugin="dataTable"
                  >
                    <thead *ngIf="searchResults?.length == 0" style="overflow: hidden;">
                      <tr>
                        <th class="d-none d-md-table-row"><span class="text-muted"></span></th>
                        <th><span class="text-muted"></span></th>
                        <th><span class="text-muted"></span></th>
                        <th><span class="text-muted d-none d-sm-block"></span></th>
                        <th style="max-width: 30px;"><span class="text-muted d-none d-sm-block"></span></th>
                      </tr>
                    </thead>
                    <tbody *ngIf="searchResults?.length != 0">
                      <tr
                        *ngFor="let product of searchResults; let i = index"
                        product-tr
                        [ngClass]="{ priceNotActive: product.hasOwnProperty('isActive') && !product.isActive }"
                        [product]="product"
                      ></tr>
                    </tbody>
                    <tbody *ngIf="searchResults?.length == 0">
                      <tr *ngIf="searchResults?.length == 0 && loadingDone">
                        <td colspan="5" class="no-data-available text-center">
                          <span>There is no data matched with your filter</span>
                        </td>
                      </tr>
                    </tbody>
                    <tbody
                      style="min-height: 100px; display: block;"
                      *ngIf="searchResults?.length == 0 && !loadingDone"
                    ></tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
