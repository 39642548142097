import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/i18n';
import { Shell } from '@app/shell/shell.service';
import { ProductsComponent } from './products/products.component';
import { HomeComponent } from './dashboard/home.component';
import { ProfileComponent } from './profile/profile.component';
import { FaqComponent } from './faq/faq.component';
import { NetworkingComponent } from './networking/networking.component';
import { PostComponent } from './networking/post/post.component';
import { ShellComponent } from '@app/shell/shell.component';
import { PostReplyComponent } from './networking/post-reply/post-reply.component';
import { AuthGuard } from '@app/auth';
import { GlobalPostsComponent } from './networking/global-posts/global-posts.component';
import { PostDetailsComponent } from './networking/post-details/post-details.component';
import { MyPostsComponent } from './networking/my-posts/my-posts.component';
import { NewPostComponent } from './networking/new-post/new-post.component';
import { PricingComponent } from './pricing/pricing.component';
import { DeviceCheckComponent } from './device-check/device-check.component';
import { ProviderGradeComponent } from './provider-grade/provider-grade.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  // { path: 'networking', loadChildren: () => import(`./networking/networking.module`).then(m => m.NetworkingModule),data: { reuse: true }, },

  // {
  //   path: 'networking',
  //   component: ShellComponent,
  //   canActivate: [AuthGuard],
  //   children: [
  //     { path: '', redirectTo: 'posts', pathMatch: 'full' },
  //     {
  //       path: 'posts',
  //       component: NetworkingComponent,
  //       children: [
  //         { path: '', redirectTo: 'global', pathMatch: 'full' },
  //         {
  //           path: 'global',
  //           component: GlobalPostsComponent,
  //         },
  //         {
  //           path: 'mypost',
  //           component: MyPostsComponent,
  //         },
  //         {
  //           path: 'details',
  //           component: PostDetailsComponent,
  //         },
  //         {
  //           path: 'newPost',
  //           component: NewPostComponent,
  //         },
  //       ],
  //     },
  //   ],
  //   data: { reuse: true },
  // },

  Shell.childRoutes([{ path: 'home', component: HomeComponent, data: { title: extract('Home') } }]),
  Shell.childRoutes([
    { path: 'products', component: ProductsComponent, data: { title: extract('Mobility Intel - Product List') } },
  ]),
  Shell.childRoutes([
    { path: 'profile', component: ProfileComponent, data: { title: extract('Mobility Intel - Profile') } },
  ]),
  // Shell.childRoutes([
  //   { path: 'pricing', component: PricingComponent, data: { title: extract('Mobility Intel - Pricing') } },
  // ]),
  // Shell.childRoutes([
  //   { path: 'deviceCheck', component: DeviceCheckComponent, data: { title: extract('Mobility Intel - Pricing') } },
  // ]),
  Shell.childRoutes([
    {
      path: 'providerGrade/:id',
      component: ProviderGradeComponent,
      data: { title: extract('Mobility Intel - Providers') },
    },
  ]),
  Shell.childRoutes([{ path: 'faq', component: FaqComponent, data: { title: extract('Mobility Intel - FAQ') } }]),
  // Shell.childRoutes([
  //   { path: 'networking', component: NetworkingComponent, data: { title: extract('Mobility Intel - Networking') } },
  // ]),
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HomeRoutingModule {}
