import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { IAttribute } from '@app/home/models/attributes.model';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap, map } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { IPriceRange } from '@app/home/models/priceRange.model';
import { IProduct } from '@app/home/models/search.model';
import { ProductsService } from '../products.service';

@Component({
  selector: '[product-tr]',
  templateUrl: './productRow.component.html',
  providers: [NgsRevealConfig],
})
export class ProductRowComponent implements OnInit {
  @Input() product: IProduct;

  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private productsService: ProductsService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  loader = false;
  calculateRatio(price: number, ratio: number) {
    return ((ratio / 100) * price).toFixed(2);
  }
  addToWatchList(referenceId: string) {
    this.loader = true;
    this.productsService.addToWatchList$(referenceId).subscribe((g) => {
      this.product.isExistInWatchList = true;
      this.loader = false;
    });
  }
  removeFromWatchList(referenceId: string) {
    this.loader = true;
    this.productsService.removeFromWatchList$(referenceId).subscribe((g) => {
      this.product.isExistInWatchList = false;
      this.loader = false;
    });
  }

  ngOnInit() {}
}
