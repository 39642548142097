import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { IProviderGradeForReturn } from '@app/home/models/providerGrade.model';

@Component({
  selector: '[provider-grade-row]',
  templateUrl: './provider-grade-row.component.html',
})
export class ProviderGradeRowComponent implements OnInit {
  @Input() rowData: IProviderGradeForReturn;

  ngOnInit() {}
}
