<div class="pt-1" [ngsReveal]="{ reset: false }">
  <div class="mb-2 ml-2 row row-sm">
    <div class="col">
      <div class="d-flex">
        <div
          style="font-size: 16px; font-style: normal; font-weight: normal; line-height: 23px;"
          class="text-white text-md"
        >
          <div
            *ngIf="priceRange.min || priceRange.max"
            style="height: 25px; width: 5px; background-color: #1ed300; display: block; float: left; margin-right: 5px;"
          ></div>
          <div
            *ngIf="!priceRange.min && !priceRange.max"
            style="height: 25px; width: 5px; background-color: #a4aca2; display: block; float: left; margin-right: 5px;"
          ></div>
          <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <i class="fa fa-angle-down" *ngIf="!isCollapsed && loaded"></i>
            <i class="fa fa-angle-right" *ngIf="isCollapsed && loaded"></i>
            <i class="fa fa-angle-right" *ngIf="!loaded"></i>
            {{ title }}</a
          >
        </div>
        <div *ngIf="!loaded" class="ml-2 loading"></div>
      </div>
    </div>
  </div>
  <div id="collapseExample" [ngbCollapse]="isCollapsed">
    <div *ngIf="loaded" style="margin-left: 30px;" class="row mb-2">
      <div class="col-4">
        <div class="input-group flex bg-light rounded">
          <input
            [(ngModel)]="priceRange.min"
            type="text"
            (change)="changeprice()"
            (keypress)="numberOnly($event)"
            class="form-control form-control-sm no-bg no-border no-shadow search"
            placeholder="Min"
          />
        </div>
      </div>

      <div class="col-4">
        <div class="input-group flex bg-light rounded">
          <input
            [(ngModel)]="priceRange.max"
            type="text"
            (change)="changeprice()"
            (keypress)="numberOnly($event)"
            class="form-control form-control-sm no-bg no-border no-shadow search"
            placeholder="Max"
          />
        </div>
      </div>
      <div class="col-2">
        <button (click)="searchClick()" class="btn btn-raised btn-wave btn-icon btn-rounded mb-2 teal text-white">
          <i class="fas fa-search small text-fade"></i>
        </button>
      </div>
    </div>
  </div>
</div>
