import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError, timer, Subject } from 'rxjs';
import { retry, catchError, retryWhen, take, delay, concatMap, map, takeUntil, shareReplay, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { IAttribute, INamedAttribute } from '../models/attributes.model';
import { ISearchResponse } from '../models/search.model';
import { ISearchCriteria } from '../models/searchCriteria.model';
import { WatchList } from '../models/watchList.model';
import { IEditionSearchForReturn } from '../models/pricing.model';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  constructor(private http: HttpClient) {}
  brandsServiceRoute = '/Brands';
  categoriesServiceRoute = '/Categories';
  editionsServiceRoute = '/Editions';
  watchListServiceRoute = '/WatchList';
  pricingServiceRoute = '/Pricing';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  csvHttpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/csv',
    }),
  };
  public selectedItems: Subject<string> = new Subject<string>();

  getBrands$(): Observable<IAttribute[]> {
    return this.http.get<IAttribute[]>(environment.serverUrl + this.brandsServiceRoute, this.httpOptions).pipe(
      map((body: any) => body.result),
      shareReplay(10),
      catchError(() => of('Error, could not load brands from api'))
    );
  }

  getCategories$(): Observable<INamedAttribute[]> {
    return this.http.get<INamedAttribute[]>(environment.serverUrl + this.categoriesServiceRoute, this.httpOptions).pipe(
      map((body: any) => body.result),
      shareReplay(10),
      catchError(() => of('Error, could not load categories from api'))
    );
  }

  search$(searchCriteria: ISearchCriteria): Observable<ISearchResponse> {
    return this.http
      .post<ISearchResponse>(environment.serverUrl + this.editionsServiceRoute, searchCriteria, this.httpOptions)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not search from api'))
      );
  }
  addToWatchList$(editionReferenceId: string): Observable<WatchList> {
    return this.http
      .post<WatchList>(environment.serverUrl + this.watchListServiceRoute + '/' + editionReferenceId, this.httpOptions)
      .pipe(
        map((body: any) => body.result[0].editions),
        catchError(() => of('Error, could not load watchlist from api'))
      );
  }
  removeFromWatchList$(editionReferenceId: string): Observable<WatchList> {
    return this.http
      .delete<WatchList>(
        environment.serverUrl + this.watchListServiceRoute + '/' + editionReferenceId,
        this.httpOptions
      )
      .pipe(
        map((body: any) => body.result[0].editions),
        catchError(() => of('Error, could not load watchlist from api'))
      );
  }

  postPricingRequest$(fileToUpload: FormData): Observable<IEditionSearchForReturn[]> {
    return this.http.post<IEditionSearchForReturn[]>(
      environment.serverUrl + this.pricingServiceRoute,
      fileToUpload,
      this.csvHttpOptions
    );
  }
}
