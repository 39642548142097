<div style="min-height: 200px; overflow: hidden; max-height: 600px;" [ngsReveal]="{ reset: false }" class="card pb-3">
  <ngx-spinner [name]="'ratioHistory'" bdColor="rgba(2, 4, 3, 0.21)" type="ball-grid-pulse" size="default">
    <p style="margin-top: 45px; color: white;">Loading...</p>
  </ngx-spinner>
  <div class="p-3-4">
    <div class="d-flex">
      <div>
        <div class="text-white text-md text-highlight">Market Prices Chart</div>
      </div>
      <span class="flex"></span>
      <!-- <div *ngIf="watchListitems?.length > 0">
          <div
            *ngIf="!showUpdateWatchList"
            class="btn-group sideDropdown ml-2"
            ngbDropdown
            container="body"
            role="group"
            aria-label="Button group with nested dropdown"
          >
            <a ngbDropdownToggle><i class="fas fa-ellipsis-h"></i></a>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button *ngIf="!uiExtend" (click)="extendWatchList()" ngbDropdownItem>Extend</button>
              <button *ngIf="uiExtend" (click)="reduceWatchList()" ngbDropdownItem>Reduce</button>
              <button (click)="editWatchList()" ngbDropdownItem>Edit</button>
            </div>
          </div>
          <div *ngIf="showUpdateWatchList">
            <a (click)="saveWatchListUpdate()" class="btn btn-sm btn-white">Save</a>
          </div>
        </div> -->
    </div>
  </div>
  <div class="row row-sm ml-2 mt-4 text-muted">
    <div class="col">
      <div class="row row-sm">
        <div class="col"></div>
        <div class="col-auto mr-4">
          <form class="form-inline">
            <div class="form-group">
              <div class="input-group">
                <input
                  class="form-control"
                  name="dp"
                  (dateSelect)="changeDate($event)"
                  [(ngModel)]="selectedDate"
                  ngbDatepicker
                  [minDate]="{ year: 2020, month: 9, day: 1 }"
                  [maxDate]="today"
                  positionTarget="bottom"
                  [outsideDays]="'collapsed'"
                  #d="ngbDatepicker"
                  readonly="readonly"
                />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
                    <i class="fas fa-calendar-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="row row-sm">
        <div class="col-12">
          <div class="flex text-center">
            <div>
              <apx-chart
                #chart
                [series]="chartOptions.series"
                [chart]="chartOptions.chart"
                [noData]="noData"
                [dataLabels]="chartOptions.dataLabels"
                [plotOptions]="chartOptions.plotOptions"
                [yaxis]="chartOptions.yaxis"
                [xaxis]="chartOptions.xaxis"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
