import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { IRatioLiveItem } from '@app/home/models/ratioLiveItems.model';
import { IUser } from '@app/user/user.model';
import { UserService } from '@app/user/userService.service';

@Component({
  selector: 'profile-page',
  templateUrl: './profile.component.html',
  providers: [NgsRevealConfig],
})
export class ProfileComponent implements OnInit {
  user: IUser = {};

  constructor(revealConfig: NgsRevealConfig, private spinner: NgxSpinnerService, private userService: UserService) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }

  loaded: boolean = true;

  ngOnInit() {
    this.userService.getUser$().subscribe((f) => (this.user = f));
  }
}
