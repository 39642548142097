<app-loader></app-loader>
<div class="page-hero" id="page-hero">
  <div class="padding d-flex" style="padding-top: 0px;">
    <div class="page-title">
      <h2 class="text-md text-highlight">Dashboard</h2>
      <small *ngIf="username" class="text-muted"
        >Welcome onboard,
        <strong>{{ username }}</strong>
      </small>
    </div>
    <div class="flex"></div>
  </div>
</div>
<div class="padding" style="padding-top: 0px;">
  <div *ngIf="!staticAlertClosed" class="row">
    <div class="col-12">
      <ngb-alert class="alert alert-info fade show p-4 mb-4" [type]="'success'" (closed)="close()">
        <div class="d-flex">
          <span class="w-40 avatar circle gd-info"><i data-feather="award"></i></span>
          <div class="d-sm-flex">
            <div class="mx-3 align-self-start">
              <h6 *ngIf="username">Hello {{ username }},</h6>
              <small
                >Seems it's the first time you use Mobility Intel Panel, Would you like to take a tour in the
                website?</small
              >
            </div>
            <div class="mx-3">
              <a (click)="openTour()" class="btn btn-sm btn-rounded btn-outline-info my-2">Start now</a>
            </div>
          </div>
        </div>
      </ngb-alert>
    </div>
  </div>
  <div class="row row-sm sr">
    <div [ngClass]="{ 'col-lg-5': !isWatchListExtend, 'col-lg-12': isWatchListExtend }" class="col-md-12">
      <div class="row row-sm">
        <div class="col-md-12">
          <div class="row row-sm">
            <div class="col-12">
              <watch-list (isExtend)="watchListExtend($event)"></watch-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <div class="row row-sm">
        <div class="col-md-12">
          <div class="row row-sm">
            <div class="col-12">
              <comparison-chart></comparison-chart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="row row-sm">
        <div class="col-md-12">
          <div class="row row-sm">
            <div class="col-12">
              <ratioLive-main></ratioLive-main>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row row-sm">
        <div class="col-md-12">
          <div class="row row-sm">
            <div class="col-12">
              <ratioHistory-main></ratioHistory-main>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
