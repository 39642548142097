import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { IAttribute } from '@app/home/models/attributes.model';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap, map } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { IPriceRange } from '@app/home/models/priceRange.model';

@Component({
  selector: 'price-box',
  templateUrl: './priceBox.component.html',
  providers: [NgsRevealConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceBoxComponent implements OnInit {
  @Input() title: string;
  @Input() isCollapsed: boolean = false;
  @Output() selectedPrice = new EventEmitter<IPriceRange>();
  @Output() searchEvent = new EventEmitter();

  constructor(revealConfig: NgsRevealConfig, private spinner: NgxSpinnerService) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }

  priceRange: IPriceRange = new IPriceRange();
  loaded: boolean = true;

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  changeprice() {
    this.selectedPrice.emit(this.priceRange);
  }
  searchClick() {
    this.searchEvent.emit();
  }
  ngOnInit() {}
}
