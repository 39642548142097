export class IPostResponse {
  result: IPost[];
  totalCount: number;
}

export class IPost {
  referenceId: string;
  user: INetworkingUser;
  createdDate: string;
  title: string;
  description: string;
  comments: IComments[];
  topics: ITopic[];
}
export class IComments {
  referenceId: string;
  user: INetworkingUser;
  description: string;
  createdDate: string;
  isError?: boolean;
}
export class INetworkingUser {
  referenceId?: string;
  businessName?: string;
  fullName?: string;
  userName?: string;
  email?: string;
  imageUrl?: string;
}

export class ITopic {
  referenceId?: string;
  name?: string;
}

export class IReplyRequest {
  postReferenceId: string;
  description: string;
}

export class IPostRequest {
  description: string;
  topics: ITopic[];
}
