<div *ngIf="postsToShow">
  <div *ngFor="let item of postsToShow">
    <app-post (onDelete)="delete($event)" [data]="item"></app-post>
  </div>
</div>

<div *ngIf="showIfNoResults" [ngsReveal]="{ reset: false }" class="card post-item pb-2">
  <div class="card-body text-center">
    <p class="card-text">
      There is no result to show
    </p>
  </div>
</div>

<div *ngIf="showLoading" [ngsReveal]="{ reset: false }" class="card post-item pb-2">
  <div class="card-header">
    <div class="d-sm-flex no-shrink">
      <span class="w-96 circleLoading">
        <ngx-skeleton-loader appearance="circle" count="1"> </ngx-skeleton-loader>
      </span>
      <div class="px-sm-4 my-3 my-sm-0 flex">
        <h2 class="text-md" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </h2>
        <small class="d-block text-fade" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </small>
      </div>
      <div style="float: right;">
        <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p style="width: 60%;" class="card-text">
      <ngx-skeleton-loader count="3"> </ngx-skeleton-loader>
    </p>
  </div>
  <div class="row row-sm">
    <div class="col-lg-8 col-sm-8 pl-4">
      <div class="row row-sm">
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex"></div>
    <div class="col-lg-1 col-sm-3 pb-2">
      <div class="row row-sm"></div>
    </div>
  </div>
</div>

<div *ngIf="showLoading" [ngsReveal]="{ reset: false }" class="card post-item pb-2">
  <div class="card-header">
    <div class="d-sm-flex no-shrink">
      <span class="w-96 circleLoading">
        <ngx-skeleton-loader appearance="circle" count="1"> </ngx-skeleton-loader>
      </span>
      <div class="px-sm-4 my-3 my-sm-0 flex">
        <h2 class="text-md" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </h2>
        <small class="d-block text-fade" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </small>
      </div>
      <div style="float: right;">
        <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p style="width: 60%;" class="card-text">
      <ngx-skeleton-loader count="3"> </ngx-skeleton-loader>
    </p>
  </div>
  <div class="row row-sm">
    <div class="col-lg-8 col-sm-8 pl-4">
      <div class="row row-sm">
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex"></div>
    <div class="col-lg-1 col-sm-3 pb-2">
      <div class="row row-sm"></div>
    </div>
  </div>
</div>

<div *ngIf="showLoading" [ngsReveal]="{ reset: false }" class="card post-item pb-2">
  <div class="card-header">
    <div class="d-sm-flex no-shrink">
      <span class="w-96 circleLoading">
        <ngx-skeleton-loader appearance="circle" count="1"> </ngx-skeleton-loader>
      </span>
      <div class="px-sm-4 my-3 my-sm-0 flex">
        <h2 class="text-md" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </h2>
        <small class="d-block text-fade" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </small>
      </div>
      <div style="float: right;">
        <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p style="width: 60%;" class="card-text">
      <ngx-skeleton-loader count="3"> </ngx-skeleton-loader>
    </p>
  </div>
  <div class="row row-sm">
    <div class="col-lg-8 col-sm-8 pl-4">
      <div class="row row-sm">
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex"></div>
    <div class="col-lg-1 col-sm-3 pb-2">
      <div class="row row-sm"></div>
    </div>
  </div>
</div>
