import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { retry, catchError, retryWhen, take, delay, concatMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AuthService } from '@app/auth/auth.service';
import { PersonalInfo, BusinessInfo } from '@app/home/models/profile.model';

@Injectable({
  providedIn: 'root',
})
export class CompleteRegistrationService {
  constructor(private http: HttpClient) {}
  userServiceRoute = '/users';
  businessServiceRoute = '/Business';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  postPersonalInfo$(personalInfo: PersonalInfo): Observable<any> {
    return this.http.put<PersonalInfo>(
      environment.serverUrl + this.userServiceRoute,
      JSON.stringify(personalInfo),
      this.httpOptions
    );
  }

  postBusinessInfo$(businessInfo: BusinessInfo): Observable<any> {
    return this.http.post<BusinessInfo>(
      environment.serverUrl + this.businessServiceRoute,
      JSON.stringify(businessInfo),
      this.httpOptions
    );
  }

  verifySmsCode$(code: string): Observable<string> {
    return this.http.put<string>(
      environment.serverUrl + `${this.userServiceRoute}/VerifyPhoneNumber?code=${code}`,
      this.httpOptions
    );
  }
}
