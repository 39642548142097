export class BusinessInfo {
  name: string;
  website: string;
  yearEstablish: string;
  vat: string;
  businessField: string;
  ownershipType: string;
  agreement: string;
}

export class PersonalInfo {
  fullName: string;
  referenceId: string;
  phoneNumber: string;
  imageUrl: string;
}
