import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/user/userService.service';
import { IComments, IPost } from '../models/post.model';
import { NetworkingService } from '../networking.service';

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss'],
})
export class PostDetailsComponent implements OnInit {
  postsToShow: IPost = new IPost();
  loadingpost: boolean = true;
  replyForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private networkingService: NetworkingService,
    private route: ActivatedRoute,
    private userService: UserService
  ) {}
  postId: string;
  ngOnInit(): void {
    this.replyForm = this.formBuilder.group({
      message: ['', Validators.required],
    });
    this.postId = this.route.snapshot.paramMap.get('id');
    this.networkingService.getPostById$(this.postId).subscribe((g) => {
      this.postsToShow = g;
      this.loadingpost = false;
    });
  }

  submitted: boolean = false;
  get replyFormControles() {
    return this.replyForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.replyForm.invalid) {
      console.log(this.replyForm);
      return;
    }
    var textToSend = this.replyForm.get('message').value;
    this.replyForm.controls['message'].setValue('');
    this.userService.getUser$(true).subscribe((user) => {
      var refId = Math.floor(Math.random() * 100);
      let tempComment: IComments = {
        referenceId: refId.toString(),
        createdDate: new Date().toISOString(),
        description: textToSend,
        user: {
          fullName: user.fullName,
          businessName: user.business.name,
          imageUrl: user.imageUrl,
        },
      };
      this.postsToShow.comments.push(tempComment);
      this.networkingService
        .postReplyById$({
          postReferenceId: this.postId,
          description: textToSend,
        })
        .subscribe(
          (g) => {},
          (error) => {
            this.postsToShow.comments.find((g) => g.referenceId == refId.toString()).isError = true;
          }
        );
    });
  }
}
