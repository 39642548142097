import { Component, OnInit, ViewChild } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexYAxis,
  ApexXAxis,
  ApexPlotOptions,
  ApexNoData,
  ChartComponent,
} from 'ng-apexcharts';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../dashboard.service';
import { IRatioLiveItem } from '@app/home/models/ratioLiveItems.model';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
};
@Component({
  selector: 'ratioHistory-main',
  templateUrl: './ratioHistory.component.html',
  providers: [NgsRevealConfig],
})
export class RatioHistoryComponent implements OnInit {
  public chartOptions: Partial<ChartOptions>;
  public noData: ApexNoData = {
    text: 'Sorry,, There are no data for the selected date',
    align: 'center',
    verticalAlign: 'middle',
    offsetX: 0,
    offsetY: 0,
    style: {
      color: '#fff',
      fontSize: '14px',
      fontFamily: undefined,
    },
  };

  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private calendar: NgbCalendar,
    private dashboardService: DashboardService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }
  loaded: boolean = true;
  selectedDate: NgbDateStruct;
  today: any;
  ngOnInit() {
    this.today = this.calendar.getToday();
    this.selectedDate = this.today;
    this.spinner.show('ratioHistory');
    this.chartOptions = {
      series: [
        {
          name: 'Market Ratio',
          data: [],
        },
      ],
      chart: {
        type: 'bar',

        height: 350,
        toolbar: {
          tools: {
            download: false,
          },
        },
      },
      plotOptions: {
        bar: {
          rangeBarOverlap: true,
          endingShape: 'rounded',
          horizontal: true,
          colors: {
            ranges: [
              {
                from: -100,
                to: -0.01,
                color: '#AF4C4C',
              },
              {
                from: 0,
                to: 100,
                color: '#4CAF50',
              },
            ],
          },
          columnWidth: '80%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        title: {
          text: 'Brands Ratio',
        },
        labels: {
          formatter: function (y) {
            return y + '%';
          },
        },
      },
      xaxis: {
        type: 'category',
        categories: this.brandRatioData.map((g) => g.brandName),

        labels: {
          rotate: -90,
        },
      },
    };
    this.getRatio('');
    this.spinner.hide('ratioHistory');
  }
  changeDate(date: any) {
    console.log(date);
    this.getRatio(this.selectedDate);
  }
  brandRatioData: IRatioLiveItem[] = [];
  @ViewChild('chart', { static: false }) chart: ChartComponent;

  getRatio(date: any) {
    this.spinner.show('ratioHistory');
    var dateToSend;
    var dateStr;
    if (!date) {
      dateStr = null;
    } else {
      dateToSend = new Date(date.year, date.month - 1, date.day + 1, 0, 0, 0, 0);

      var json = JSON.stringify(dateToSend);
      var dateStr = JSON.parse(json);
    }
    this.dashboardService.getRatioHistory(dateStr).subscribe((r) => {
      this.brandRatioData = r;
      // this.chartOptions.series = [
      //   {
      //     name: 'Market Ratio',
      //     data: this.brandRatioData.map((g) => g.ratio),
      //   },
      // ];
      this.chartOptions.xaxis.categories = this.brandRatioData.map((g) => g.brandName);
      this.chart.updateOptions({
        xaxis: {
          type: 'category',
          // categories: ['Apply', 'Samsung', 'HTC', 'Lenovo', 'Sony', 'HP', 'Philips', 'Xiaumi', 'Thinkpad'],
          categories: this.brandRatioData.map((g) => g.brandName),
          labels: {
            rotate: -90,
          },
        },
      });
      this.chart.updateSeries([
        {
          name: 'Market Ratio',
          data: this.brandRatioData.map((g) => g.ratio),
        },
      ]);
      this.spinner.hide('ratioHistory');
    });
  }
}
