import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  ChangeDetectionStrategy,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { IRatioLiveItem } from '@app/home/models/ratioLiveItems.model';
import { DashboardService } from '../dashboard.service';

@Component({
  selector: 'ratioLive-main',
  templateUrl: './ratioLive.component.html',
  providers: [NgsRevealConfig],
})
export class RatioLiveComponent implements OnInit {
  ratioLiveItems: IRatioLiveItem[];
  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }

  loaded: boolean = false;
  getRatio() {
    this.dashboardService.getRatioHistory('').subscribe((r) => {
      this.ratioLiveItems = r;
      this.loaded = true;
    });
  }
  ngOnInit() {
    this.getRatio();
  }
}
