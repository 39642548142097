<div>
  <div style="position: absolute; min-height: 100%; min-width: 100%;" class="layout-row bg-body">
    <div class="layout-column mh-100 flex">
      <div id="header" class="page-header" data-class="bg-body">
        <div class="navbar navbar-expand">
          <!-- Navbar collapse -->
          <div class="collapse navbar-collapse order-2 order-lg-1" id="navbarToggler">
            <div class="input-group m-2 my-lg-0">
              <div class="input-group-prepend"></div>
              <div class="navbar">
                <!-- brand -->
                <a class="navbar-brand">
                  <!-- <img src="../assets/img/logo.png" alt="..."> -->
                  <span class="hidden-folded d-inline l-s-n-1x">MobilityIntel</span>
                </a>
                <!-- / brand -->
              </div>
            </div>
            <ul class="nav sideDropdown navbar-menu order-1 order-lg-2">
              <!-- User dropdown menu -->
              <li ngbDropdown class="nav-item dropdown">
                <a
                  ngbDropdownToggle
                  id="navbarDropdown2"
                  role="button"
                  class="nav-link d-flex align-items-center px-2 text-color"
                >
                  <span class="avatar w-24" style="margin: -2px; margin-right: 5px;"><img [src]="userPic()" /></span
                  ><span>{{ username }}</span>
                </a>
                <div
                  ngbDropdownMenu
                  aria-labelledby="navbarDropdown2"
                  class="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn"
                >
                  <!-- <a ngbDropdownItem class="dropdown-item">Need help?</a> -->
                  <div class="dropdown-divider"></div>
                  <a ngbDropdownItem class="dropdown-item" (click)="logout()">Sign out</a>
                </div>
              </li>
              <!-- Navarbar toggle btn -->
              <li class="nav-item d-lg-none">
                <a href="#" class="nav-link px-2" data-toggle="collapse" data-toggle-class data-target="#navbarToggler">
                  <i data-feather="search"></i>
                </a>
              </li>
              <li class="nav-item d-lg-none">
                <a class="nav-link px-1" data-toggle="modal" data-target="#aside">
                  <i data-feather="menu"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div id="content" class="flex bg-body">
        <div class="page-hero page-container" id="page-hero">
          <div class="padding d-flex">
            <div class="page-title">
              <div class="row row-sm">
                <div class="text-center col-lg-2 col-md-12 col-sm-12" style="margin-top: -20px;">
                  <img style="width: 100%; max-width: 100px;" src="assets\logo4dark.png" />
                </div>
                <div class="col-lg-10 col-md-12 col-sm-12">
                  <h2 class="text-md text-highlight">Welcome to Mobility Intel</h2>
                  <small class="">
                    It's just a few seconds to complete your registration, please fill the required information to
                    start</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page-content page-container" id="page-content ">
          <div *ngIf="!personalInfo && !verifyCodePage" class="row row-sm">
            <div class="col-lg-3 col-sm-1"></div>
            <div class="col-lg-6 col-sm-12">
              <form [formGroup]="personalRegisterForm" (ngSubmit)="onSubmit()">
                <div [ngsReveal]="{ reset: false }" class="card">
                  <ngx-spinner [fullScreen]="false" bdColor="rgba(2, 4, 3, 0.21)" type="ball-grid-pulse" size="default">
                    <p style="margin-top: 30px; color: white;">Loading...</p>
                  </ngx-spinner>
                  <div class="card-body">
                    <div class="form-row">
                      <div class="form-group col-sm-12">
                        <label>Full Name</label>
                        <input
                          type="text"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': submitted && f.fullName.errors }"
                          formControlName="fullName"
                          id="fullName"
                        />
                        <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
                          <div *ngIf="f.fullName.errors.required">Full Name is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Phone Number</label>
                      <input
                        class="form-control"
                        type="tel"
                        mask="+00000000000000000"
                        [ngClass]="{ 'is-invalid': submitted && f.phone.errors }"
                        formControlName="phone"
                        placeholder="Your phone number"
                      />
                      <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Phone Number is required</div>
                      </div>
                    </div>
                    <div class="text-right">
                      <button class="btn btn-primary">
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-3 col-sm-1"></div>
          </div>
          <div *ngIf="verifyCodePage" class="row row-sm">
            <div class="col-lg-3 col-sm-1"></div>
            <div class="col-lg-6 col-sm-12">
              <form [formGroup]="confrimForm" (ngSubmit)="confirmPhone()">
                <div [ngsReveal]="{ reset: false }" class="card">
                  <div class="card-body">
                    <ngx-spinner
                      [fullScreen]="false"
                      bdColor="rgba(2, 4, 3, 0.21)"
                      type="ball-grid-pulse"
                      size="default"
                    >
                      <p style="margin-top: -30px; color: white;">Loading...</p>
                    </ngx-spinner>
                    <div style="display: block;" class="form-row text-center">
                      <div class="form-group col-sm-12">
                        <label>SMS Code</label>
                        <input
                          style="font-size: 30px; display: block; text-align: center;"
                          type="tel"
                          class="form-control"
                          [ngClass]="{ 'is-invalid': (submitted && c.smsCode.errors) || invalidSmsCode }"
                          mask="000000"
                          formControlName="smsCode"
                          id="smsCode"
                        />
                        <div *ngIf="submitted && c.smsCode.errors" class="invalid-feedback">
                          <div *ngIf="c.smsCode.errors.required">SMS code is required</div>
                        </div>
                        <div *ngIf="invalidSmsCode" class="invalid-feedback">
                          <div>SMS code is not valid</div>
                        </div>
                      </div>
                      <!-- <div class="col-12">
                        <a style="color: #f54394;">Didn't receive the code!?</a>
                      </div> -->
                    </div>

                    <div class="text-right">
                      <a (click)="skipSms()">
                        Skip
                      </a>
                      <button class="btn btn-primary">
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-3 col-sm-1"></div>
          </div>
          <div *ngIf="personalInfo" class="row row-sm">
            <div class="col-lg-3 col-sm-1"></div>
            <div class="col-lg-6 col-sm-12">
              <form [formGroup]="businessRegisterForm" (ngSubmit)="onSubmitBusinessInfo()">
                <div [ngsReveal]="{ reset: false }" class="card">
                  <div class="card-body">
                    <ngx-spinner
                      [fullScreen]="false"
                      bdColor="rgba(2, 4, 3, 0.21)"
                      type="ball-grid-pulse"
                      size="default"
                    >
                      <p style="margin-top: -30px; color: white;">Loading...</p>
                    </ngx-spinner>
                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label>Business Name*</label>
                        <input
                          type="text"
                          minlength="2"
                          [ngClass]="{ 'is-invalid': submitted && b.businessName.errors }"
                          formControlName="businessName"
                          class="form-control"
                          id="businessName"
                        />
                        <div *ngIf="submitted && b.businessName.errors" class="invalid-feedback">
                          <div *ngIf="b.businessName.errors.required">Business Name is required</div>
                        </div>
                        <div *ngIf="submitted && b.businessName.errors?.minlength" class="invalid-feedback">
                          <div *ngIf="b.businessName.errors.required">
                            Business Name must be at least 2 characters long.
                          </div>
                        </div>
                      </div>
                      <div class="form-group col-sm-6">
                        <label>Website*</label>
                        <input
                          type="text"
                          [ngClass]="{ 'is-invalid': submitted && b.website.errors }"
                          formControlName="website"
                          class="form-control"
                          id="website"
                        />
                        <div *ngIf="submitted && b.website.errors" class="invalid-feedback">
                          <div *ngIf="b.website.errors.required">Website is required</div>
                        </div>
                      </div>
                    </div>

                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label>Established year* </label>
                        <input
                          type="text"
                          mask="0000"
                          [ngClass]="{ 'is-invalid': submitted && b.year.errors }"
                          formControlName="year"
                          class="form-control"
                          id="year"
                        />
                        <div *ngIf="submitted && b.year.errors" class="invalid-feedback">
                          <div *ngIf="b.year.errors.required">Established year is required</div>
                        </div>
                      </div>
                      <div class="form-group col-sm-6">
                        <label>EIN / VAT*</label>
                        <input
                          type="text"
                          [ngClass]="{ 'is-invalid': submitted && b.vat.errors }"
                          formControlName="vat"
                          class="form-control"
                          id="vat"
                        />
                        <div *ngIf="submitted && b.vat.errors" class="invalid-feedback">
                          <div *ngIf="b.vat.errors.required">EIN / VAT is required</div>
                        </div>
                      </div>
                    </div>
                    <p class="text-muted">Business Field</p>
                    <div class="form-row">
                      <div class="form-group col-sm-4">
                        <label class="md-check">
                          <input
                            type="radio"
                            checked
                            value="Warehouse"
                            formControlName="businessField"
                            checked
                            name="businessField"
                          />
                          <i class="green"></i>
                          Warehouse
                        </label>
                      </div>
                      <div class="form-group col-sm-4">
                        <label class="md-check">
                          <input type="radio" value="Retail" formControlName="businessField" name="businessField" />
                          <i class="green"></i>
                          Retail
                        </label>
                      </div>
                      <div class="form-group col-sm-4">
                        <label class="md-check">
                          <input type="radio" value="Other" formControlName="businessField" name="businessField" />
                          <i class="green"></i>
                          Other
                        </label>
                      </div>
                    </div>
                    <p class="text-muted">Business Structure</p>
                    <div class="form-row">
                      <div class="form-group col-sm-6">
                        <label class="md-check">
                          <input
                            type="radio"
                            checked
                            value="SoleProprietorship"
                            formControlName="businessStructure"
                            name="businessStructure"
                          />
                          <i class="green"></i>
                          Sole Proprietorship
                        </label>
                      </div>
                      <div class="form-group col-sm-6">
                        <label class="md-check">
                          <input
                            type="radio"
                            value="Partnership"
                            formControlName="businessStructure"
                            name="businessStructure"
                          />
                          <i class="green"></i>
                          Partnership
                        </label>
                      </div>
                      <div class="form-group col-sm-6">
                        <label class="md-check">
                          <input
                            type="radio"
                            value="Corporation"
                            formControlName="businessStructure"
                            name="businessStructure"
                          />
                          <i class="green"></i>
                          Corporation
                        </label>
                      </div>
                      <div class="form-group col-sm-6">
                        <label class="md-check">
                          <input
                            type="radio"
                            value="Other"
                            formControlName="businessStructure"
                            name="businessStructure"
                          />
                          <i class="green"></i>
                          Other
                        </label>
                      </div>
                    </div>

                    <div class="checkcard">
                      <label class="ui-check" [ngClass]="{ 'is-invalid': submitted && b.agreement.errors }">
                        <input
                          type="checkbox"
                          [ngClass]="{ 'is-invalid': submitted && b.agreement.errors }"
                          formControlName="agreement"
                          name="agreement"
                        />
                        <i></i> I agree to the
                        <a
                          target="_blank"
                          href="https://mobilityintelpolicy.s3.amazonaws.com/Privacy+Policy.htm"
                          class="text-primary"
                          >Privacy Policy and Terms of Service
                        </a>
                      </label>
                    </div>

                    <div *ngIf="invalidBusinessInfo" style="font-size: 15px; color: brown;">
                      {{ invalidBusinessInfo }}
                    </div>
                    <div *ngIf="submitted && b.agreement.errors" style="font-size: 15px; color: brown;">
                      Accept Terms of Service is required
                    </div>
                    <div class="text-right">
                      <button type="submit" class="btn btn-primary">Next</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-lg-3 col-sm-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
