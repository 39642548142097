import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ITopic } from './models/post.model';
import { NetworkingService } from './networking.service';
import * as jquery from 'jquery';
import 'select2';
import { AuthService } from '@app/auth/auth.service';
import { environment } from '@env/environment';
import { finalize } from 'rxjs/operators';
import { SnotifyPosition, SnotifyService } from 'ng-snotify';

@Component({
  selector: 'app-networking',
  templateUrl: './networking.component.html',
  styleUrls: ['./networking.component.scss'],
})
export class NetworkingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private networkingService: NetworkingService,
    private auth: AuthService,
    private snotifyService: SnotifyService
  ) {}
  topics: ITopic[];
  topicLoading: boolean = false;
  showAddNewTopicSectionMobile: boolean = false;
  addNewTopicBarShowed: boolean = false;
  showAddNewTopicSectionMobileInit() {
    this.initTagsSelect();
    this.showAddNewTopicSectionMobile = true;
  }
  addNewTopicBar() {
    this.initTagsSelect();
    this.addNewTopicBarShowed = true;
  }
  ngOnInit(): void {
    this.initSort();
    this.calculateTopics();
    this.initTagsSelect();
    this.getTopics();
  }
  initTagsSelect() {
    this.auth.getTokenSilently$().subscribe((token) => {
      jquery('#id_label_multiple2,#id_label_multiple2_mobile').select2({
        placeholder: 'Topic Name',
        ajax: {
          headers: { Authorization: `Bearer ${token}` },
          url: environment.serverUrl + '/tags',
          processResults: function (data) {
            return {
              results: $.map(data, function (obj) {
                return { id: obj, text: obj };
              }),
            };
          },
          data: function (params) {
            var query = {
              tagName: params.term,
            };
            return query;
          },
        },
      });
    });
  }
  getTopics() {
    this.topicLoading = true;
    this.networkingService.getTopics$().subscribe((f) => {
      this.topics = f;
      this.topicLoading = false;
    });
  }
  selectedItems: string[] = [];
  addToNetworkFilter(item: string) {
    var currentItemsroute = this.route.snapshot.children[0].paramMap.getAll('items')[0];
    var currentItems;
    if (currentItemsroute) {
      currentItems = currentItemsroute.split(',');
      if (currentItems.includes(item)) {
        currentItems = currentItems.filter((h) => h != item);
      } else currentItems.push(item);
    } else currentItems = [item];
    this.selectedItems = currentItems;
    console.log(this.selectedItems);
    this.router.navigate(['/networking/posts/global', { items: currentItems, s: this.sortType }]);
  }

  calculateTopics() {
    var currentItemsroute = this.route.snapshot.children[0].paramMap.getAll('items')[0];
    if (currentItemsroute) {
      this.selectedItems = currentItemsroute.split(',');
    }
  }

  addNewTopic(source: string = '') {
    this.topicLoading = true;
    let topicToAdd = this.getSelectedTagsItems(source);
    this.showAddNewTopicSectionMobile = false;
    if (!topicToAdd) {
      return;
    }
    this.networkingService.addTopic(topicToAdd[0].name).subscribe((f) => {
      this.snotifyService.success('A new topic has been added', {
        position: SnotifyPosition.centerTop,
        timeout: 3000,
      });
      this.getTopics();
    });
  }
  hotPostSort: boolean = false;
  clearSelectedTagsItems() {
    $('#id_label_multiple2,#id_label_multiple2_mobile').val(null);
    $('#id_label_multiple2,#id_label_multiple2_mobile').html('');
  }

  getSelectedTagsItems(source: string) {
    var selectedItems =
      source == 'mobile'
        ? $('#id_label_multiple2_mobile').find(':selected')
        : $('#id_label_multiple2').find(':selected');
    var items = selectedItems.map(function (index, ele) {
      return ele.innerText;
    });
    return items.toArray().map((g) => {
      let topic: ITopic = {
        name: g,
      };
      return topic;
    });
  }
  refreshSelectedItems() {
    this.router.navigate(['/networking/posts/global', { items: this.selectedItems, s: this.sortType }]);
  }
  removeTopic(item: ITopic) {
    this.selectedItems = this.selectedItems.filter((g) => g != item.name);
    this.topics = this.topics.filter((g) => g.name != item.name);
    this.networkingService.removeTopic(item.name).subscribe((g) => {
      this.topicLoading = false;
    });
    this.refreshSelectedItems();
  }

  isSelectedTopics(item: ITopic) {
    if (this.selectedItems.includes(item.name)) {
      return true;
    }
  }
  sortType: string = 'newest';
  initSort() {
    var sortType = this.route.snapshot.children[0].paramMap.getAll('s')[0];
    if (!sortType) this.sortType = 'newest';
    this.sortType = sortType;
  }
  changeSort(type: string) {
    this.sortType = type;
    this.refreshSelectedItems();
  }
  globalClicked() {
    this.selectedItems = [];
  }

  mypostClicked() {
    this.selectedItems = [];
  }
}
