import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, timer, from, Subject } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from '@env/environment';
import { WatchListItem } from '../models/watchList.model';
import { IEditionHourlyForReturn, IWatchListChart, IWatchListChartResponse } from '../models/historyHourly.model';
import { IRatioLiveItem } from '../models/ratioLiveItems.model';
import { IPostResponse, IPost, IReplyRequest, IComments, IPostRequest, ITopic } from './models/post.model';
import { IOrderByCriteria, ISearchCriteria } from '../models/searchCriteria.model';

import { AuthService } from '@app/auth/auth.service';
const route = {
  post: () => '/posts',
  postById: (id: string) => '/posts/' + id,
  comment: () => '/Comments',
  userTopics: () => '/UserTopics',
};
@Injectable({
  providedIn: 'root',
})
export class NetworkingService {
  constructor(private http: HttpClient, private auth: AuthService) {}

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  getPosts$(): Observable<IPostResponse> {
    return this.http.get<IPostResponse>(environment.serverUrl + route.post(), this.httpOptions).pipe(
      map((body: any) => body),
      shareReplay(1)
    );
  }

  deletePost(postId: string): Observable<IPostResponse> {
    return this.http
      .delete<IPostResponse>(environment.serverUrl + route.post() + '/' + postId, this.httpOptions)
      .pipe(map((body: any) => body));
  }

  filterPosts$(context: ISearchCriteria): Observable<IPostResponse> {
    if (!context.orderByCriteria) {
      context.orderByCriteria = new IOrderByCriteria();
      context.orderByCriteria.keyword = 'NONE';
      context.orderByCriteria.sortType = 'Desc';
    }

    return this.http.post<IPostResponse>(environment.serverUrl + route.post(), context, this.httpOptions).pipe(
      map((body: any) => body),
      shareReplay(1),
      catchError(() => of('Error, could not load data from api'))
    );
  }

  getPostById$(id: string): Observable<IPost> {
    return this.http.get<IPost>(environment.serverUrl + route.postById(id), this.httpOptions).pipe(
      map((body: any) => body),
      catchError(() => of('Error, could not load data from api'))
    );
  }

  postReplyById$(context: IReplyRequest): Observable<IComments> {
    return this.http
      .put<IComments>(environment.serverUrl + route.comment(), context, this.httpOptions)
      .pipe(map((body: any) => body));
  }

  newPost$(context: IPostRequest): Observable<IPost> {
    return this.http
      .put<IPost>(environment.serverUrl + route.post(), context, this.httpOptions)
      .pipe(map((body: any) => body));
  }

  getTopics$(): Observable<ITopic[]> {
    return this.http.get<ITopic[]>(environment.serverUrl + route.userTopics(), this.httpOptions).pipe(
      map((body: any) => body),
      catchError(() => of('Error, could not load data from api'))
    );
  }

  removeTopic(name: string) {
    return this.http
      .delete<ITopic>(environment.serverUrl + route.userTopics() + '?topicName=' + name, this.httpOptions)
      .pipe(map((body: any) => body));
  }

  addTopic(name: string) {
    return this.http
      .put<ITopic>(environment.serverUrl + route.userTopics() + '?topicName=' + name, null, this.httpOptions)
      .pipe(map((body: any) => body));
  }
}
