export class ISearchCriteria {
  take: number;
  skip: number;
  brandNames: string[];
  referenceId: string;
  categoryNames: string[];
  productName: string;
  editionName: string;
  userName: string;
  email: string;
  businessName: string;
  externalReferenceId: string;
  minPrice: number;
  maxPrice: number;
  attributeCriteria: IAttributeCriteria[];
  orderByCriteria: IOrderByCriteria;
  topicName?: string[];
}

export class IAttributeCriteria {
  attributeKey: string;
  attributeValues: string[];
}
export class IOrderByCriteria {
  keyword: string;
  sortType: string;
}

export const orderByCriteriaElements = [
  {
    name: 'Best Match',
    value: 'None',
    type: 'ASC',
  },
  {
    name: 'Decreasing by Price',
    value: 'CurrentAverage',
    type: 'DESC',
  },
  {
    name: 'Increasing by Price',
    value: 'CurrentAverage',
    type: 'ASC',
  },
  {
    name: 'Decreasing by Ratio',
    value: 'Ratio',
    type: 'DESC',
  },
  {
    name: 'Increasing by Ratio',
    value: 'Ratio',
    type: 'ASC',
  },
];
