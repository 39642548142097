import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, retry, retryWhen, delay, take } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { genericRetryStrategy } from './rxjsUtil';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root',
})
export default class ErrorHandlerInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retryWhen(
        genericRetryStrategy({
          maxRetryAttempts: 5,
          scalingDuration: 2000,
          excludedStatusCodes: [400, 403, 401, 409],
        })
      ),
      // retryWhen((errors) => errors.pipe(delay(1000), take(60))),
      catchError((error) => this.errorHandler(error))
    );
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpEvent<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response);
    }
    throw response;
  }
}
