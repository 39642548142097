<!-- <header>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="https://github.com/ngx-rocket" translate>APP_NAME</a>
    <button
      class="navbar-toggler"
      type="button"
      aria-controls="navbar-menu" 
      aria-label="Toggle navigation"
      (click)="toggleMenu()"
      [attr.aria-expanded]="!menuHidden"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbar-menu" class="collapse navbar-collapse float-xs-none" [ngbCollapse]="menuHidden">
      <div class="navbar-nav">
        <a class="nav-item nav-link text-uppercase" routerLink="/home" routerLinkActive="active">
          <i class="fas fa-home"></i>
          <span translate>Home</span>
        </a>
        <a class="nav-item nav-link text-uppercase" routerLink="/about" routerLinkActive="active">
          <i class="fas fa-question-circle"></i>
          <span translate>About</span>
        </a>
      </div>
      <div class="navbar-nav ml-auto">
        <app-language-selector inNavbar="true" menuClass="dropdown-menu dropdown-menu-right"></app-language-selector>
        <div class="nav-item" ngbDropdown>
          <a id="user-dropdown" class="nav-link" ngbDropdownToggle>
            <i class="fas fa-user-circle"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="user-dropdown" class="dropdown-menu dropdown-menu-right">
            <h6 class="dropdown-header">
              <span translate>Logged in as</span> <b>{{ username }}</b>
            </h6>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="logout()" translate>Logout</button>
          </div>
        </div>
      </div>
    </div>
  </nav>
</header> -->

<div class="navbar navbar-expand-lg">
  <!-- Navbar collapse -->

  <ul class="nav navbar-menu order-1 order-lg-2">
    <!-- User dropdown menu -->
    <li ngbDropdown class="nav-item dropdown">
      <a
        ngbDropdownToggle
        id="navbarDropdown2"
        role="button"
        class="nav-link d-flex align-items-center px-2 text-color"
      >
        <span class="avatar w-24" style="margin: -2px; margin-right: 5px;"><img [src]="userPic()" alt="..." /></span
        ><span>{{ username }}</span>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarDropdown2"
        class="dropdown-menu dropdown-menu-right w mt-3 animate fadeIn"
      >
        <a [routerLink]="['/profile']" ngbDropdownItem class="dropdown-item">
          <span>Profile</span>
        </a>
        <!-- <a [routerLink]="['/faq']" ngbDropdownItem class="dropdown-item">Need help?</a> -->
        <div class="dropdown-divider"></div>
        <a ngbDropdownItem class="dropdown-item" (click)="toggleTheme()">
          <span>Use </span>
          <span *ngIf="!isDarkTheme" class="badge bg-secondary text-uppercase">Dark Mode</span>
          <span
            *ngIf="isDarkTheme"
            style="background-color: #f8f9fa !important; color: black !important;"
            class="badge bg-light text-uppercase"
            >Light Mode</span
          >
        </a>
        <!-- <a ngbDropdownItem class="dropdown-item" href="page.setting.html">
          <span>Account Settings</span>
        </a> -->

        <a ngbDropdownItem class="dropdown-item" (click)="logout()">Sign out</a>
      </div>
    </li>
    <!-- Navarbar toggle btn -->
    <li class="nav-item d-lg-none">
      <a href="#" class="nav-link px-2" data-toggle="collapse" data-toggle-class data-target="#navbarToggler">
        <i data-feather="search"></i>
      </a>
    </li>
    <li class="nav-item d-lg-none">
      <a class="nav-link px-1" data-toggle="modal" data-target="#aside">
        <i data-feather="menu"></i>
      </a>
    </li>
  </ul>
</div>
