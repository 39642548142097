<td style="width: 250px; padding-top: 5px; padding-bottom: 0px; background-color: #161d33;">
  <div class="item-except text-sm">
    <span class="item-title text-color h-1x bold">{{ matchEdition.productName }}</span>
    <div class="item-except text-sm h-1x">
      <span class="brand_color"> {{ matchEdition.brandName }}</span> /
      <span class="category_color">{{ matchEdition.categoryName }}</span>
    </div>
  </div>
</td>

<td style="width: 400px; padding-top: 5px; padding-bottom: 0px; background-color: #161d33;">
  <div class="item-except text-sm">
    <span class="small-font text-white"> {{ matchEdition.name }} </span>
  </div>
</td>

<td style="width: 100px; padding-top: 5px; padding-bottom: 0px; background-color: #161d33;">
  <div class="item-except text-sm">
    <span class="big-font text-white"> ${{ matchEdition.currentAverage }} </span>
  </div>
</td>

<td style="width: 40px; padding-top: 5px; padding-bottom: 0px; background-color: #161d33;">
  <div class="row row-sm">
    <div *ngIf="loader" class="col-12 centeredLoading">
      <div class="loading"></div>
    </div>
    <div *ngIf="!loader" class="col-12 center">
      <a (click)="selectCorrentMatch()" style="color: #79de4a; font-size: 10px;" class="bold text-sm">
        <div class="row row-sm">
          <div class="col-12 text-center" style="font-size: 12px;">Approve</div>
        </div>
      </a>
    </div>
  </div>
</td>
