<div *ngIf="data" [ngsReveal]="{ reset: false }" class="card reply-item arrow">
  <div class="card-header">
    <div class="d-sm-flex no-shrink">
      <a data-pjax-state="">
        <span class="w-40 avatar gd-primary" data-toggle-class="loading">
          <span class="avatar-status on b-white avatar-right"></span>
          <img *ngIf="data.user?.imageUrl" src="{{ data.user?.imageUrl }}" alt="." />
          <ngx-avatar *ngIf="!data.user?.imageUrl" name="{{ data.user?.fullName }}"></ngx-avatar>
        </span>
      </a>
      <div class="px-sm-4 my-3 my-sm-0 flex">
        <h2 class="text-sm">{{ data.user?.fullName }}</h2>
        <small class="d-block text-fade text-xs">{{ data.user?.businessName }}</small>
      </div>
      <div style="float: right;" class="">
        <p class="text-xs text-muted">{{ data.createdDate }}</p>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p class="card-text">
      {{ data.description }}
    </p>
    <p *ngIf="data.isError" class="card-text" style="color: red; font-size: medium;">
      Sorry, an unexpected error occurred and your message was not sent.
    </p>
  </div>
</div>
