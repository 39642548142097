<div *ngIf="loadingpost" [ngsReveal]="{ reset: false }" class="card post-item pb-2">
  <div class="card-header">
    <div class="d-sm-flex no-shrink">
      <span class="w-96 circleLoading">
        <ngx-skeleton-loader appearance="circle" count="1"> </ngx-skeleton-loader>
      </span>
      <div class="px-sm-4 my-3 my-sm-0 flex">
        <h2 class="text-md" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </h2>
        <small class="d-block text-fade" style="width: 200px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </small>
      </div>
      <div style="float: right;">
        <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
      </div>
    </div>
  </div>
  <div class="card-body">
    <p style="width: 60%;" class="card-text">
      <ngx-skeleton-loader count="3"> </ngx-skeleton-loader>
    </p>
  </div>
  <div class="row row-sm">
    <div class="col-lg-8 col-sm-8 pl-4">
      <div class="row row-sm">
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
        <div class="mr-2" style="width: 60px;">
          <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
        </div>
      </div>
    </div>
    <div class="flex"></div>
    <div class="col-lg-1 col-sm-3 pb-2">
      <div class="row row-sm"></div>
    </div>
  </div>
</div>

<app-post *ngIf="!loadingpost" [data]="postsToShow"></app-post>
<div *ngIf="!loadingpost" class="replyPost">
  <ul *ngIf="postsToShow.comments?.length > 0" class="timeline">
    <li *ngFor="let item of postsToShow.comments" class="timeline-inverted">
      <div class="timeline-badge"><i class="glyphicon glyphicon-check"></i></div>
      <div class="timeline-panel">
        <app-post-reply [data]="item"></app-post-reply>
      </div>
    </li>
  </ul>
  <div class="card gedf-card" [ngsReveal]="{ reset: false }">
    <form [formGroup]="replyForm" (ngSubmit)="onSubmit()">
      <div class="card-body">
        <div class="tab-pane fade show active" id="posts" role="tabpanel" aria-labelledby="posts-tab">
          <div class="form-group">
            <label class="sr-only" for="message">post</label>
            <textarea
              formControlName="message"
              class="form-control"
              rows="3"
              placeholder="What are you thinking?"
            ></textarea>
            <div *ngIf="submitted && replyFormControles.message.errors" class="invalid-feedback">
              <div *ngIf="replyFormControles.message.errors.required">*Please enter reply message before sending</div>
            </div>
          </div>
        </div>
        <div class="btn-toolbar justify-content-between">
          <div class="btn-group">
            <button class="btn btn-primary">Reply</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div *ngIf="loadingpost" class="replyPost">
  <ul class="timeline">
    <li class="timeline-inverted">
      <div class="timeline-panel">
        <div [ngsReveal]="{ reset: false }" class="card reply-item arrow">
          <div class="card-header">
            <div class="d-sm-flex no-shrink">
              <a data-pjax-state="">
                <span class="w-56 circleLoading">
                  <ngx-skeleton-loader appearance="circle" count="1"> </ngx-skeleton-loader>
                </span>
              </a>
              <div class="px-sm-4 my-3 my-sm-0 flex">
                <h2 style="width: 200px;" class="text-sm"><ngx-skeleton-loader count="1"> </ngx-skeleton-loader></h2>
                <small style="width: 200px;" class="d-block text-fade text-xs">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader
                ></small>
              </div>
              <div style="float: right;" class="">
                <p style="width: 200px;" class="text-xs text-muted">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </p>
              </div>
            </div>
          </div>
          <div class="card-body">
            <p style="width: 400px;" class="card-text">
              <ngx-skeleton-loader count="2"> </ngx-skeleton-loader>
            </p>
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>
