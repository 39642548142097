import { Component, OnInit, AfterViewInit, Output, Input, EventEmitter } from '@angular/core';
import { NgsRevealConfig } from 'ngx-scrollreveal';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../../dashboard.service';
import { AuthService } from '@app/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { WatchListItem } from '@app/home/models/watchList.model';
import { ProductsService } from '@app/home/products/products.service';

@Component({
  selector: '[watch-list-item]',
  templateUrl: './watch-list-item.component.html',
  providers: [NgsRevealConfig],
})
export class WatchListItemComponent implements OnInit {
  @Input() item: WatchListItem;
  @Input() showUpdateWatchList: boolean = false;
  @Output() onDelete = new EventEmitter<WatchListItem>();
  @Output() onAddToChart = new EventEmitter<WatchListItem>();

  /* UI variables */
  searchTermWatchList: string;
  staticAlertClosed = false;
  isExpandDetails: boolean = false;
  /* process variables */
  watchListitems: WatchListItem[] = [];
  watchListCatFilterItems: WatchListItem[] = [];
  watchListCatSelectedValues: string[] = [];
  constructor(
    revealConfig: NgsRevealConfig,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    public auth: AuthService,
    private cookieService: CookieService,
    private productsService: ProductsService
  ) {
    revealConfig.duration = 5000;
    revealConfig.easing = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
  }

  ngOnInit() {}

  addToWatchListChart(edition: WatchListItem) {
    this.onAddToChart.emit(edition);
  }

  removeFromWatchlist(refId: string) {
    this.spinner.show('watchList');
    this.productsService.removeFromWatchList$(refId).subscribe((g) => {
      this.spinner.hide('watchList');
      this.dashboardService.removeFromWatchChartEvent.next(refId);

      this.onDelete.emit(this.item);
    });
  }

  getProductName(name: string) {
    return name.split(':')[0];
  }
  getSpecValues(name: string) {
    return name.split(':')[1];
  }
  calculateRatio(price: number, ratio: number) {
    return ((ratio / 100) * price).toFixed(2);
  }
}
