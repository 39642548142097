export class IEditionHourlyForReturn {
  editionReferenceId: string;
  editionName: string;
  values: IEditionHourlyItem[];
}

export class IEditionHourlyItem {
  timeFrame: number;
  averagePrice: number;
}

export enum HourlyTimeFrameEnum {
  Day,
  FiveDays,
}

export class IWatchListChart {
  editionName: string;
  editionReferenceId: string;
  color?: string;
}

export class IWatchListChartResponse {
  result: IWatchListChartItemsResponse[];
}

export class IWatchListChartItemsResponse {
  editions: IWatchListChart[];
}

export const chartHistoryDurationElements = [
  {
    name: '1d',
    value: 'Day',
  },
  {
    name: '5d',
    value: 'FiveDays',
    type: 'ASC',
  },
  {
    name: '1m',
    value: 'OneMonth',
  },
  {
    name: '6m',
    value: 'SixMonths',
  },
  {
    name: '1y',
    value: 'OneYear',
  },
  {
    name: 'Max',
    value: 'Max',
  },
];
export const chartColors = ['#ffc107', '#2196F3', '#4CAF50', '#f54394', '#14bae4'];
