<div>
  <div class="page-hero" id="page-hero">
    <div class="padding d-flex">
      <div class="page-title">
        <h2 class="text-md text-highlight">Device Check</h2>
        <small class="text-muted">Check device information based on DeviceId (IMEI Number)</small>
      </div>
    </div>
  </div>
  <div class="page-content" id="page-content">
    <div class="padding">
      <div class="row">
        <div class="col-3"></div>
        <div class="col-sm-12 col-md-12 col-lg-6">
          <div class="list list-row block">
            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color" label for="deviceCheckValueInput">IMEI Number</a>
                <div class="col-sm-6" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    type="text"
                    [(ngModel)]="deviceCheckValue"
                    required
                    minlength="15"
                    pattern="[0-9]{15,}"
                    class="form-control form-control-sm ng-valid"
                    name="deviceCheckValueInput"
                    id="deviceCheckValueInput"
                    #deviceCheckValueInput="ngModel"
                  />
                </div>
                <div
                  class="col-sm-6 alert"
                  *ngIf="
                    deviceCheckValueInput.invalid && (deviceCheckValueInput.dirty || deviceCheckValueInput.touched)
                  "
                  style="padding-left: 0%; padding-top: 5px;"
                >
                  <span class="text-danger">Most be numbers and min length is 15</span>
                </div>
              </div>
            </div>
            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="item-except text-muted text-sm h-2x">
                <button
                  (click)="onDeviceCheck()"
                  class="btn btn-raised btn-wave w-sm purple"
                  [disabled]="isProcessing || deviceCheckValueInput.invalid"
                >
                  Check
                </button>
              </div>
              <div class="item-except text-muted text-sm h-2x">
                <div *ngIf="isProcessing" class="col-12 centeredLoading">
                  <div class="loading"></div>
                </div>
                <div *ngIf="isExceedLimit" class="col-12 centeredLoading">
                  <span class="text-danger">You reached maximum limit of device check</span>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="deviceCheckForReturn != null && !isProcessing && !isExceedLimit" class="list list-row block">
            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">Brand</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.brandname"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">Model</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.modelname"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>

            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">Manufacturer</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.manufacturer"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">Device type</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.devicetype"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>

            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">Operating System</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.operatingsys"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">NFC</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.nfc"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>

            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">wLAN</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.wLAN"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">Bluetooth</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.bluetooth"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>

            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">blackliststatus</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.blackliststatus"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">greyliststatus</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.greyliststatus"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            *ngIf="
              deviceCheckForReturn != null &&
              deviceCheckForReturn.pricingEngine != null &&
              !isProcessing &&
              !isExceedLimit
            "
            class="list list-row block"
          >
            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">Product Name (MIE)</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.pricingEngine.productName"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">Current Average</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.pricingEngine.currentAverage"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>

            <div [ngsReveal]="{ reset: false }" class="list-item">
              <div class="flex">
                <a class="item-author text-color">Open</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.pricingEngine.open"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>

              <div class="flex">
                <a class="item-author text-color">Close</a>
                <div class="col-sm-12" style="padding-left: 0%; padding-top: 5px;">
                  <input
                    class="form-control form-control-sm"
                    [(ngModel)]="deviceCheckForReturn.pricingEngine.close"
                    type="text"
                    placeholder="..."
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
