import { Component, OnInit } from '@angular/core';
import { NetworkingService } from '../networking.service';
import { IPost } from '../models/post.model';
import { ActivatedRoute } from '@angular/router';
import { ISearchCriteria } from '@app/home/models/searchCriteria.model';

@Component({
  selector: 'app-global-posts',
  templateUrl: './global-posts.component.html',
  styleUrls: ['./global-posts.component.scss'],
})
export class GlobalPostsComponent implements OnInit {
  postsToShow: IPost[] = [];
  showLoading: boolean = true;
  showIfNoResults: boolean = false;
  constructor(private networkingService: NetworkingService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe((g) => {
      var items = g.items?.toString();
      var sort = g.s?.toString();

      if (items) {
        this.globalSearchCriteria.topicName = items.split(',');
      } else this.globalSearchCriteria.topicName = null;

      if (sort) {
        this.globalSearchCriteria.orderByCriteria = {
          keyword: sort == 'newest' ? 'NONE' : 'CommentsCount',
          sortType: 'Desc',
        };
      }
      this.getData();
    });
    this.getData();
  }
  delete(post: IPost) {
    this.postsToShow = this.postsToShow.filter((r) => r.referenceId != post.referenceId);
  }
  globalSearchCriteria: ISearchCriteria = new ISearchCriteria();
  getData() {
    this.networkingService.filterPosts$(this.globalSearchCriteria).subscribe((g) => {
      this.showLoading = false;
      this.postsToShow = g.result;
      if (this.postsToShow.length == 0) {
        console.log('ddd', this.postsToShow.length);
        this.showIfNoResults = true;
      } else this.showIfNoResults = false;
    });
  }
}
