<div style="min-height: 550px; overflow: hidden; max-height: 600px;" [ngsReveal]="{ reset: false }" class="card pb-3">
  <ngx-spinner
    [name]="'watchList'"
    [fullScreen]="false"
    bdColor="rgba(2, 4, 3, 0.21)"
    type="ball-grid-pulse"
    size="default"
  >
    <p style="margin-top: 45px; color: white;">Loading...</p>
  </ngx-spinner>
  <div class="p-3-4">
    <div class="d-flex">
      <div>
        <div class="text-white text-md text-highlight">Watch List</div>
      </div>
      <span class="flex"></span>
      <div *ngIf="watchListitems?.length > 0">
        <div
          *ngIf="!showUpdateWatchList"
          class="btn-group sideDropdown ml-2"
          ngbDropdown
          container="body"
          role="group"
          aria-label="Button group with nested dropdown"
        >
          <a ngbDropdownToggle><i class="fas fa-ellipsis-h"></i></a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button *ngIf="!uiExtend" (click)="extendWatchList()" ngbDropdownItem>Extend</button>
            <button *ngIf="uiExtend" (click)="reduceWatchList()" ngbDropdownItem>Reduce</button>
            <button (click)="editWatchList()" ngbDropdownItem>Edit</button>
          </div>
        </div>
        <div *ngIf="showUpdateWatchList">
          <a (click)="saveWatchListUpdate()" class="btn btn-sm btn-white">Save</a>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!showUpdateWatchList" style="margin-bottom: 0px;" class="row row-sm">
    <div *ngIf="watchListCatActive" class="col">
      <div class="row">
        <div class="col-12">
          <div>
            <ul style="list-style: none;">
              <li
                class="btn-group btn-group-toggle mx-1"
                data-toggle="buttons"
                *ngFor="let item of watchListCat; let i = index"
              >
                <label class="btn badge badge-sm badge-pill" ngbButtonLabel>
                  <input type="checkbox" ngbButton (change)="watchlistCatFilter($event, item)" />
                  {{ item }}
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="watchListCatActive" class="col-8 text-right pr-3">
                    <div class="btn-group btn-group-toggle mb-3" data-toggle="buttons">
                      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" >
                        <div ngxSlickItem *ngFor="let item of watchListCat; let i = index" class="slide">
                          <label  class="btn" ngbButtonLabel>
                            <input type="checkbox" ngbButton (change)="watchlistCatFilter($event, item)" /> {{ item }}
                          </label>
                        </div>
                      </ngx-slick-carousel>
                    </div>
                  </div> -->
  </div>
  <div style="margin-top: 0px;" class="row ml-1 mr-1">
    <div *ngIf="watchListitems?.length > 0" class="col">
      <div class="input-group flex bg-light rounded">
        <input
          [(ngModel)]="searchTermWatchList"
          (ngModelChange)="searchWatchlist()"
          type="text"
          class="form-control form-control-sm no-bg no-border no-shadow search"
          placeholder="Filter"
        />
        <span class="input-group-append">
          <span class="btn no-bg no-shadow"><i class="fas fa-search small text-fade"></i></span>
        </span>
      </div>
    </div>
  </div>
  <div *ngIf="showEmptyValue" class="row row-sm ml-2 mt-4 text-muted">
    <div class="col">
      <div *ngIf="watchListitems?.length == 0 || watchListUIItems?.length == 0" class="row row-sm">
        <div class="col flex text-center">
          <i style="font-size: 40px;" class="fas fa-eye-slash"> </i>
        </div>
      </div>
      <div class="row row-sm">
        <div class="col">
          <div *ngIf="watchListitems?.length == 0" class="flex text-center">
            <span class="item-title text-color">No products in watchlist yet..</span><br />
            <span style="font-size: 12px;" class="item-title text-color"
              >Start adding new products by clicking in add button below..</span
            >
          </div>
          <div *ngIf="watchListitems?.length > 0 && watchListUIItems?.length == 0" class="flex text-center">
            <span class="item-title text-color">There's no product meeting your filter..</span><br />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row row-sm pb-5">
    <div class="col">
      <perfect-scrollbar [ngClass]="{ extendedWatchlist: uiExtend, collapesedWatchlist: !uiExtend }">
        <table class="table table-theme v-middle m-0">
          <tbody>
            <tr
              *ngFor="let item of watchListUIItems; let i = index"
              watch-list-item
              (onDelete)="onDeleteItem($event)"
              (onAddToChart)="addToWatchListChart($event)"
              [showUpdateWatchList]="showUpdateWatchList"
              [item]="item"
            ></tr>
            <tr *ngIf="isLoading">
              <td style="width: 200px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 100px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="isLoading">
              <td style="width: 200px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 100px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="isLoading">
              <td style="width: 200px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 100px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
            <tr *ngIf="isLoading">
              <td style="width: 200px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td class="flex" style="width: 100px;">
                <div class="flex">
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                  <span class="item-title text-color h-1x bold">
                    <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                  </span>
                </div>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
              <td style="width: 30px;">
                <span class="item-title text-color h-1x bold">
                  <ngx-skeleton-loader count="1"> </ngx-skeleton-loader>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </perfect-scrollbar>
    </div>
  </div>
  <div class="p-2 mt-2 belowButton">
    <div class="text-center">
      <div class="col">
        <a [routerLink]="['/products']" class="link_color">+Add To Your Watch List</a>
      </div>
    </div>
  </div>
</div>
